const weightUnits = {
  kg: "kilogram",
  lb: "pound",
  oz: "ounce",
  g: "gram",
};

export const useFormatWeight = (
  weight: number,
  options: {
    valueUnit?: string;
    unit?: string;
    withoutUnit?: boolean;
  } = {}
) => {
  if (weight === null || weight === undefined) return "";

  const { unit, withoutUnit, valueUnit = "g" } = options;
  const defaultWeightUnit = usePreferredWeightUnit() || "g";
  const unitKey = (unit || defaultWeightUnit) as keyof typeof weightUnits;

  if (weight === null || weight === undefined) return unit;

  const { $i18n } = useNuxtApp();
  const newWeight = convertWeight(weight, valueUnit, unitKey);

  if (withoutUnit)
    return newWeight.toLocaleString($i18n.locale.value, {
      maximumFractionDigits: 2,
    });

  return newWeight.toLocaleString($i18n.locale.value, {
    style: "unit",
    maximumFractionDigits: 2,
    unit: weightUnits[unitKey],
  });
};

export const convertWeight = (weight: number | string, from: string, to: string) => {
  const weightMap: { [key: string]: number } = {
    kg: 1000,
    lb: 1 / 0.00220462,
    oz: 1 / 0.035274,
    g: 1,
  };

  const weightInGrams = parseFloat(weight.toString()) * weightMap[from];
  return weightInGrams / weightMap[to];
};

export const isWeightUnitImperial = (unit: string) => {
  return ["lb", "oz"].includes(unit);
};

export const usePreferredWeightUnit = () => {
  const unit = useOptionsStore().getOption("platform_units", '{"weight":"g"}') as string;
  return JSON.parse(unit).weight;
};

export const useIsPreferredWeightUnitImperial = () => {
  return computed(() => {
    return isWeightUnitImperial(usePreferredWeightUnit());
  });
};

export const updateWeightUnit = (weightUnit: string) => {
  return useOptionsStore().updateOption("platform_units", JSON.stringify({ weight: weightUnit }));
};

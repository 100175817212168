<template>
  <div class="mx-auto h-[67vh] w-full max-w-[780px] overflow-y-auto">
    <p class="text-title-section text-secondary mb-6 w-full text-center">{{ t("describe_the_contents") }}</p>
    <div class="mb-3 w-full">
      <div v-for="(order_line, index) in order_lines" :key="index" class="mb-3 flex w-full">
        <div class="border-00 w-full rounded-xl border p-2">
          <div class="bg-01 mb-2 flex w-full items-center rounded-lg px-0.5">
            <p class="text-body-sm-heavy text-secondary px-3 leading-none">{{ index + 1 }}</p>
            <Divider class="!bg-02 h-5" rotation="vertical" />
            <p class="text-body-sm text-tertiary flex gap-[3px] leading-none">
              <span v-if="order_line.qty !== ''">{{ order_line.qty }}x</span>
              <span>{{ order_line.item_number }}</span>
              <span>{{ order_line.customs[0].description }}</span>
              <span v-if="order_line.customs[0].weight !== ''"
                >på {{ order_line.customs[0].weight }}
                {{ returnSelecteditem(order_line.customs[0].unit, "unit") }},</span
              >
              <span v-if="order_line.unit_price !== ''"
                >til {{ order_line.unit_price }}
                {{ returnSelecteditem(order_line.customs[0].currency_code, "currency") }}</span
              >
              <span v-if="order_line.customs[0].origin_country">
                fra {{ returnSelecteditem(order_line.customs[0].origin_country, "country") }}</span
              >
            </p>
            <Button
              class="ml-auto"
              type="button"
              :class="index !== 0 ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'"
              :variant="ButtonVariant.Tertiary"
              :size="ComponentSize.sm"
              @click="order_lines.splice(index, 1)"
              >{{ t("delete") }}</Button
            >
          </div>
          <div class="flex w-full flex-col p-2">
            <div class="mb-3 flex w-full gap-3">
              <InputText v-model="order_line.qty" class="w-1/12" type="number" :label="t('qty')" />
              <InputText v-model="order_line.item_number" class="w-3/12" type="text" :label="t('item_number')" />
              <InputText
                v-model="order_line.customs[0].description"
                class="w-8/12"
                type="text"
                :placeholder="t('eg_computer_screens')"
                :label="t('describe_content')"
              />
            </div>
            <div class="mb-3 grid w-full grid-cols-12 gap-3">
              <InputText
                v-model="order_line.customs[0].weight"
                class="col-span-2"
                type="number"
                :label="`${t('weight')} ${t('pr_item')}`"
              />
              <DropdownSelect v-model="order_line.customs[0].unit" class="col-span-2 mt-[22px]" :options="units" />
              <InputText
                v-model="order_line.unit_price"
                class="col-span-2"
                type="number"
                :label="t('unit_price')"
                @update:model-value="(val) => setTotalPrice(index, val, 'price')"
              />
              <DropdownSelect
                v-model="order_line.customs[0].currency_code"
                class="col-span-2 mt-[22px]"
                :options="currencies"
              />
              <DropdownSelectCountry
                v-model="order_line.customs[0].origin_country"
                class="col-span-4"
                :label="t('origin_country')"
              />
            </div>
            <div class="mb-3 grid w-full grid-cols-12 gap-3">
              <InputText
                v-model="order_line.customs[0].sender_tariff"
                class="col-span-6"
                type="text"
                :label="t('sender_tariff')"
              />
              <InputText
                v-model="order_line.customs[0].receiver_tariff"
                class="col-span-6"
                type="text"
                :label="t('receiver_tariff')"
              />
            </div>
            <!-- <div class="flex gap-2.5">
              <TickerCheckbox v-model="order_line.dangerous_goods_set" />
              <p class="text-body-default text-secondary">{{ t("this_order_line_is_dangrous") }}</p>
            </div>
            <div
              class="grid overflow-hidden transition-all duration-300"
              :class="order_line.dangerous_goods_set ? 'mt-3 grid-rows-1fr pt-4' : 'grid-rows-0'"
            >
              <div
                class="-mt-1 overflow-hidden rounded-xl border-2 border-destructive px-4 transition-all duration-300"
                :class="order_line.dangerous_goods_set ? 'py-4 opacity-100' : 'opacity-0'"
              >
                <p class="text-title-body text-secondary">{{ t("dangerous_goods") }}</p>
                <div class="flex w-full gap-3">
                  <InputText
                    type="text"
                    v-model="order_line.dangerous_goods.class_code"
                    :placeholder="t('set_code')"
                    :label="t('class_code')"
                  />
                  <InputText
                    class="min-w-max"
                    type="text"
                    v-model="order_line.dangerous_goods.un"
                    :placeholder="t('set_number')"
                    :label="t('un_number')"
                  />
                  <DropdownSelect
                    v-model="order_line.dangerous_goods.package_group"
                    :label="t('package_group')"
                    :triggerText="t('select_package_group')"
                    :options="package_groups"
                  />
                  <div class="w-full">
                    <InputText
                      type="text"
                      v-model="order_line.dangerous_goods.package_type"
                      :placeholder="t('set_packaging')"
                      :label="t('packaging')"
                    />
                    <p class="text-body-sm text-quarterary">{{ t("packaging_obs") }}</p>
                  </div>
                </div>
                <InputText
                  class="mb-1 w-full"
                  type="text"
                  v-model="order_line.dangerous_goods.description"
                  :placeholder="t('set_technical_description')"
                  :label="t('teknical_description_dg')"
                />
                <p class="w-full text-body-sm text-quarterary">{{ t("technical_desc_obs") }}</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full justify-center pb-2">
      <Button class="w-full max-w-max" type="button" @click="addOrderLine">{{ t("add_order_line") }}</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const units = [
    { label: "Kg", value: "kg" },
    { label: "L", value: "ltr" },
  ];

  const currencies = [
    { label: "DKK", value: "DKK" },
    { label: "SEK", value: "SEK" },
  ];

  // const package_groups = [
  //     { label: "I", value: "I" },
  //     { label: "II", value: "II" },
  //     { label: "III", value: "III" },
  //   ];

  const countries = getCountrySelectOptions();

  onMounted(() => {
    if (!order_lines.value || order_lines.value.length === 0) {
      order_lines.value = [];
      addOrderLine();
    }
  });

  const returnSelecteditem = (key: string, type: string) => {
    switch (type) {
      case "unit":
        return units.find((u) => u.value === key)?.label;
      case "currency":
        return currencies.find((c) => c.value === key)?.label;
      case "country":
        return countries.find((c) => c.value === key)?.label;
    }
  };

  const { value: order_lines } = useField(() => "order_lines", undefined, {
    syncVModel: true,
  });

  const setTotalPrice = (index: number, val: number, unit: string) => {
    if (order_lines.value[index].unit_price && order_lines.value[index].qty) {
      switch (unit) {
        case "qty":
          order_lines.value[index].customs[0].total_price = order_lines.value[index].unit_price * val;
          break;
        case "price":
          order_lines.value[index].customs[0].total_price = val * order_lines.value[index].qty;
          break;
      }
    }
    return;
  };

  const addOrderLine = () => {
    order_lines.value.push({
      qty: "",
      item_number: "",
      unit_price: "",
      customs: [
        {
          description: "",
          total_price: "",
          currency_code: "DKK",
          sender_tariff: "",
          origin_country: undefined,
          receiver_tariff: "",
          weight: "",
          unit: "kg",
        },
      ],
      dangerous_goods_set: false,
      LQ: false,
      dangerous_goods: {
        un: "",
        description: "",
        package_type: "",
        package_group: undefined,
        class_code: "",
      },
    });
  };
</script>

<template>
  <div class="m-auto flex min-h-0 w-full max-w-[556px] flex-col gap-5 overflow-auto rounded-xl px-2 pb-3">
    <p class="text-title-section text-secondary w-full text-center">{{ t("portal_settings_title") }}</p>
    <InputText name="name" :placeholder="t('portal_name_example', { name: accountName })" :label="t('name_portal')" />
    <InputText
      v-model="portalURL"
      disabled
      :placeholder="t('portal_url_example', { name: parcedName() })"
      :label="t('portal_url')"
    />
    <InputText name="logo" :placeholder="t('portal_logo_example')" :label="t('portal_logo')" />
    <div v-if="legacy">
      <ButtonGroup name="settings.order_gate" :buttons="buttons" :label="t('return_portal_type')" />
      <p class="text-body-sm text-quarterary mt-1">{{ t("return_portal_type_message") }}</p>
      <TickerCheckbox class="mt-1" name="settings.order_number" :label="t('requires_order_number')" />
      <TickerCheckbox class="mt-1" name="settings.private" :label="t('private_return_portal')" />
    </div>
    <div>
      <ListSelector name="address" :values="addresses" value-key="type" @update:model-value="(val) => setAddress(val)">
        <template #title="{ value }">
          <div>
            <p class="text-body-lg-heavy text-secondary">{{ t("organisation_address") }}</p>
            <p class="text-body-default text-tertiary">{{ value.street1 }}, {{ value.zip_code }} {{ value.city }}</p>
          </div>
        </template>
      </ListSelector>
      <TickerCheckbox
        class="mt-3"
        :label="t('send_return_to_different_address')"
        name="alternative_address"
        @update:model-value="(val) => altAddress(val)"
      />
      <div v-if="values?.address !== 'SHIPPING'" class="mt-3 grid auto-cols-fr grid-cols-4 gap-3">
        <InputText
          class="col-span-2 w-full"
          :label="t('organisation_name')"
          name="settings.alternative_address.company"
        />
        <InputText
          class="col-span-2 w-full"
          :label="t('street_name_and_number')"
          name="settings.alternative_address.street"
        />
        <div class="col-span-2 grid grid-cols-6 gap-3">
          <InputText class="col-span-2 w-full" :label="t('zip_code')" name="settings.alternative_address.zip_code" />
          <InputText class="col-span-4 w-full" :label="t('city')" name="settings.alternative_address.city" />
        </div>
        <DropdownSelectCountry
          class="col-span-2"
          :label="t('country')"
          name="settings.alternative_address.country"
          trigger-type="input"
        />
        <InputText class="col-span-2" :label="t('phone_number')" name="settings.alternative_address.telephone" />
        <InputText class="col-span-2" :label="t('email')" name="settings.alternative_address.notification_email" />
      </div>
    </div>
    <InputNumber
      class="[&_.input-wrapper]:max-w-24"
      name="settings.order_gate_age_limit"
      :label="t('order_gate_limit')"
      :suffix="t('day', values?.settings?.order_gate_age_limit || 1).toLowerCase()"
    >
      <template #label_suffix>
        <Tooltip :text="t('order_gate_limit_description')" />
      </template>
    </InputNumber>
    <InputText name="settings.reference_key" :placeholder="t('portal_reference_example')" :label="t('reference')" />
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits(["updateAddress", "updateAltAddress"]);
  const { t } = useI18n();
  const props = defineProps<{
    schema: any;
    values: any;
    information: any;
    legacy: boolean;
  }>();

  const addresses = ref([]);

  const buttons = [
    {
      text: t("open_returnportal"),
      variant: "default",
      value: false,
    },
    {
      text: t("closed_returnportal"),
      variant: "default",
      value: true,
    },
  ];

  onMounted(() => {
    for (const address of props.information.contact_info) {
      if (address.type === "SHIPPING") {
        addresses.value.push(address);
      }
    }
  });

  watch(
    () => props?.values?.address,
    (newValue) => {
      if (newValue === "SHIPPING") {
        emit("updateAltAddress", false);
      }
    }
  );

  const setAddress = (val) => {
    if (val) {
      emit("updateAddress", "SHIPPING");
    } else {
      emit("updateAddress", "ALTERNATIVE");
    }
  };

  const altAddress = (val) => {
    if (val) {
      emit("updateAddress", "ALTERNATIVE");
    } else {
      emit("updateAddress", "SHIPPING");
    }
  };

  const { accountName } = storeToRefs(useWorkspacesStore());
  const parcedName = () => {
    return accountName.value?.toLowerCase().split(".")[0].replace(/ /g, "-");
  };

  const portalURL = computed(() => {
    if (!props?.values?.name) {
      return `https://return.coolrunner.dk/`;
    }

    //url encode the name
    const name = encodeURIComponent(props?.values?.name);
    return `https://return.coolrunner.dk/${name}`;
  });
</script>

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp();

  const { isUserLoggedIn } = useAuth();
  const accountsStore = useWorkspacesStore(nuxtApp.$pinia);
  const { account } = storeToRefs(accountsStore);
  const { fetchWorkspaces } = useWorkspacesStore();
  if (isUserLoggedIn.value) {
    if (to.name == "auth-logout") {
      return;
    }

    if (to.name == "auth-login" && to.query.token) return navigateTo({ name: "auth-logout", query: to.query });
    else if (to.name == "auth-login-partner" && to.query.token) {
      // add auth-login-partner to the query to redirect to the correct page after logout
      to.query.login_page = "auth-login-partner";
      return navigateTo({ name: "auth-logout", query: to.query });
    }
    try {
      if (!account.value) {
        await fetchWorkspaces(false);
        if (!account.value) {
          return navigateTo({ name: "auth-logout" });
        }
      }
    } catch (error) {
      console.error("Error updating capabilities:", error);
      return navigateTo({ name: "auth-logout" });
    }

    if (
      to.name == "auth-login" ||
      to.name == "auth-login-sso" ||
      to.name == "auth-login-intermediate" ||
      to.name == "auth-login-partner" ||
      to.name == "auth-register" ||
      to.name == "auth-sso-connect"
    ) {
      return navigateTo({ name: "shipments" });
    }
    return;
  } else {
    const exludedRoutes = [
      "auth-login",
      "auth-login-sso",
      "auth-login-intermediate",
      "auth-login-partner",
      "auth-sso-connect",
      "auth-register",
      "auth-register-workspace",
      "auth-register-user",
      "auth-reset-process",
      "auth-reset-request",
      "cookies",
    ];

    if (!exludedRoutes.includes(to.name)) {
      let redirect = to.fullPath;
      if (redirect == "/") redirect = "";
      if (redirect.includes("login")) redirect = "";
      let query = {};
      if (to.name !== "auth-logout") query = { redirect };

      query = { ...query, ...to.query };
      return navigateTo({ name: "auth-login", query });
    }

    return;
  }
});

import timezones from "../data/timezones.json";

export interface TimeZone {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
}

export const getTimezones = (): TimeZone[] => {
  return timezones;
};

export const getTimezonesAsSelectOptions = (): { value: string; label: string }[] => {
  return timezones.map((timezone) => ({
    value: timezone.tzCode,
    label: timezone.name,
  }));
};

export const getTimezoneByCode = (code: string): TimeZone | undefined => {
  return timezones.find((timezone) => timezone.tzCode === code);
};

<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SsoOptions @update:loading="setLoading" />
  </div>
</template>

<script setup lang="ts">
  import type { ISettingsFlow } from "@/types/settings";

  import SsoOptions from "./Subflow/SsoOptions.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );

  onMounted(() => {
    goToCurrentHeader();
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useSecuritySettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();
    const icon = "regular/LockSimple";
    const title = t("user_settings.security.title");
    const pageTitle = t("user_settings.security.page_title");
    const key = "security";
    const headers = [t("user_settings.security.sso.title")];
    const condition = computed(() => hasCapability("account.security"));

    return {
      icon,
      pageTitle,
      title,
      key,
      condition,
      headers,
    };
  };
</script>

<template>
  <div class="relative">
    <input
      ref="input"
      class="hidden"
      type="file"
      accept="image/png, image/jpeg, image/webp"
      @change="(val) => uploadImage(val)"
    />
    <div class="flex items-center gap-4">
      <div class="group relative">
        <Avatar :entity="entity" :size="ComponentSize.lg" :type="type" :random-color="randomColor">
          <template #overlay>
            <div
              v-if="!loading"
              class="bg-modal-overlay absolute inset-0 flex cursor-pointer items-center justify-center opacity-0 transition-opacity group-hover:opacity-100"
              @click.self="clickOnInput"
            >
              <Button icon-only :variant="ButtonVariant.Primary" @click="clickOnInput">
                <Icon src="bold/plus" />
              </Button>
            </div>
            <div v-else-if="loading" class="bg-loader-overlay-darker absolute inset-0 flex items-center justify-center">
              <Spinner class="size-5 fill-white" />
            </div>
          </template>
        </Avatar>
      </div>
      <Button :disabled="!entity.image || loading" @click="removeFile">
        {{ t("remove_image") }}
      </Button>
    </div>
    <Message v-if="error" variant="invalid">{{ error }}</Message>
    <Message v-if="message">{{ message }}</Message>
  </div>
</template>

<script setup lang="ts">
  defineOptions({
    name: "AvatarUpload",
  });

  const props = withDefaults(
    defineProps<{
      message?: string;
      error?: string;
      type: AvatarType.User | AvatarType.Webshop;
      entity?: {
        name?: string;
        image?: string | null;
      };
      randomColor?: boolean;
    }>(),
    {
      message: "",
      error: "",
      entity: () => ({
        name: "",
        image: "",
      }),
      randomColor: false,
    }
  );
  const { userId, accountId } = useWorkspacesStore();
  const { uploadTempFile } = useS3Upload();

  const emit = defineEmits(["uploaded", "removed"]);

  const loading = ref(false);

  const input = useTemplateRef<HTMLInputElement>("input");

  const { t } = useI18n();

  const clickOnInput = () => {
    if (input.value) {
      input.value.value = "";
      input.value.click();
    }
  };

  const uploadImage = (evt: Event) => {
    if (!evt.target) {
      return;
    }

    const file = (evt.target as HTMLInputElement).files?.[0];
    if (!file) {
      return;
    }

    loading.value = true;

    uploadTempFile(file, s3Path.value).then((data) => {
      const { filePath } = data;

      return homeFetch(serviceEndpoint.value, {
        method: "POST",
        body: {
          path: filePath,
        },
      })
        .then(({ data }) => {
          emit("uploaded", data?.data);
        })
        .finally(() => {
          loading.value = false;
        });
    });
  };

  const removeFile = () => {
    loading.value = true;
    return homeFetch(serviceEndpoint.value, {
      method: "DELETE",
    })
      .then(() => {
        emit("removed");
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const serviceEndpoint = computed(() => {
    switch (props.type) {
      case AvatarType.User:
        return "settings/profile/picture";
      case AvatarType.Webshop:
        return "workspace/organisation/logo";
      default:
        return "";
    }
  });

  const s3Path = computed(() => {
    switch (props.type) {
      case AvatarType.User:
        return `users/${userId}/images`;
      case AvatarType.Webshop:
        return `accounts/${accountId}/images`;
      default:
        return "";
    }
  });

  defineExpose({
    removeFile,
  });
</script>

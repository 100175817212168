import { defineStore } from "pinia";

export const useOptionsStore = defineStore(
  "Options",
  () => {
    const options = ref<IOption[]>([]);

    const setOptions = (o: IOption[]) => {
      options.value = o;
    };

    const setOption = (key: string, value: any) => {
      const index = options.value.findIndex((o) => o.key === key);
      if (index === -1) {
        options.value.push({ key, value });
      } else {
        options.value[index].value = value;
      }
    };

    const removeOption = (key: string) => {
      const index = options.value.findIndex((o) => o.key === key);
      if (index !== -1) {
        options.value.splice(index, 1);
      }
    };

    const getOption = <T = unknown>(key: string, defaultValue?: T): T | unknown => {
      return (options.value.find((o) => o.key === key)?.value as T) ?? (defaultValue as unknown);
    };

    const fetchOptions = () => {
      return homeFetch("settings/options").then((response) => {
        if (response.data) setOptions(response.data);
      });
    };

    const fetchOption = (key: string) => {
      return homeFetch(`settings/options/${key}`).then((response) => {
        setOption(key, response.data);
      });
    };

    const updateOptions = (newOptions: IOption[]) => {
      return homeFetch("settings/options", {
        method: "POST",
        body: JSON.stringify(newOptions),
      }).then((response) => {
        if (response.data?.data) setOptions(response.data.data);
      });
    };

    const updateOption = (key: string, value: any) => {
      return homeFetch(`settings/option`, {
        method: "POST",
        body: JSON.stringify({ key, value: String(value) }),
      }).then((response) => {
        const returnedValue = response.data?.data?.value;
        setOption(key, returnedValue);
      });
    };

    const deleteOption = (key: string) => {
      return homeFetch(`settings/option`, {
        method: "DELETE",
        body: JSON.stringify({ key }),
      }).then(() => {
        removeOption(key);
      });
    };

    const hasWorkspaceSidebar = computed(() => {
      return getOption("workspaceSidebar") === true;
    });

    return {
      options,
      setOptions,
      setOption,
      getOption,
      fetchOptions,
      fetchOption,
      updateOptions,
      updateOption,
      deleteOption,
      hasWorkspaceSidebar,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
);

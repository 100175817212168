<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <SettingsSection :title="t('user_settings.security.sso.title')" :description="t('settings.security.sso.description')">
    <div class="flex w-full flex-col gap-3">
      <div v-for="provider in providers" :key="provider" class="grid grid-cols-2 gap-8 px-3 py-4">
        <div class="flex items-start gap-3">
          <Icon :src="`custom/services/${provider}`" filled :size="ComponentSize.lg" />
          <div>
            <div>{{ t("sso.connect_provider", { provider: t(`sso.providers.${provider}`) }) }}</div>
            <div class="text-body-default text-quarterary">
              {{ t("sso.connect_provider_domain_description", { provider: t(`sso.providers.${provider}`) }) }}
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start gap-3">
          <div v-for="(domain, index) in getProviderDomains(provider)" :key="index" class="flex w-full items-end gap-3">
            <InputText
              :modelValue="domain"
              class="w-full"
              :label="t('mail_domain')"
              :placeholder="t('enter_mail_domain')"
              prefix="@"
              clearable
              @update:model-value="(value) => updateDomain(provider, index, value || '')"
            />
            <Button
              v-if="providerHasDomains(provider) && index !== 0"
              :variant="ButtonVariant.Destructive"
              icon-only
              @click="updateDomain(provider, index, '')"
            >
              <Icon src="regular/Trash" />
            </Button>
          </div>
          <Button :variant="ButtonVariant.PrimaryLink" @click="updateProviderDomains(provider)">
            <template #prefix>
              <Icon src="regular/Plus" />
            </template>
            {{ t("add_mail_domain") }}
          </Button>
        </div>
      </div>
    </div>
  </SettingsSection>
</template>
<script setup lang="ts">
  import _ from "lodash";

  const { t } = useI18n();
  const { fetchOption, updateOption } = useWorkspacesStore();

  const ssoOptions = ref<
    Record<
      string,
      {
        domains: string[];
      }
    >
  >({});
  const providers = ref<string[]>([]);

  const providerHasDomains = (provider: string) => {
    return ssoOptions.value[provider]?.domains?.length;
  };

  const getProviderDomains = (provider: string) => {
    if (!ssoOptions.value) return [];
    if (!ssoOptions.value[provider]) {
      return [""];
    }
    return ssoOptions.value[provider].domains?.length ? ssoOptions.value[provider].domains : [""];
  };

  const updateProviderDomains = (provider: string, index?: number, domain?: string) => {
    if (!ssoOptions.value[provider]) {
      ssoOptions.value[provider] = { domains: [""] };
      return;
    }

    if (index !== undefined && domain !== undefined) {
      ssoOptions.value[provider].domains[index] = domain;
    }

    //remove domain if index is provided
    else if (index !== undefined && domain === undefined) {
      ssoOptions.value[provider].domains.splice(index, 1);
    }

    //add new domain if no index is provided
    else if (index === undefined) {
      ssoOptions.value[provider].domains.push("");
    }
  };

  const updateDomain = (provider: string, index: number, value: string) => {
    const previousValue = ssoOptions.value[provider]?.domains?.[index] || "";

    updateProviderDomains(provider, index, value);
    debouncedUpdate(provider, index, previousValue);
  };

  const debouncedUpdate = _.debounce(async (provider: string, index: number, previousValue: string) => {
    try {
      //remove empty domains
      ssoOptions.value[provider].domains = ssoOptions.value[provider].domains.filter((d: string) => d);

      await updateOption("sso_options", JSON.stringify(ssoOptions.value));

      $toast.add({
        title: t("success"),
        type: "success",
        text: t("settings.security.sso.domain_updated", { provider: t(`sso.providers.${provider}`) }),
      });
    } catch (e) {
      console.error(e);
      // Restore previous value if update fails
      updateProviderDomains(provider, index, previousValue);
    }
  }, 270);

  const getProviders = () => {
    homeFetch<string[]>("sso/providers")
      .then((r) => {
        providers.value = r.data || [];
      })
      .catch((e) => {
        console.error(e);
      });
  };

  onMounted(() => {
    getProviders();
    fetchOption("sso_options").then((option) => {
      ssoOptions.value = JSON.parse(option) || {};
    });
  });
</script>

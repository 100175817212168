export default () => {
  const checkHash = () => {
    if (window.location.hash.startsWith("#workspace") || window.location.hash.startsWith("#preferences")) {
      const splits = window.location.hash.split("-");
      const context = splits[0];
      const key = splits[1];
      nextTick(() => {
        switch (context) {
          case "#workspace":
            if (key) {
              window.$workspace_settings.start(key);
            } else {
              window.$workspace_settings.open();
            }
            break;
          case "#preferences":
            if (key) window.$user_settings.start(key);
            else window.$user_settings.open();
            break;
        }
      });
    }
  };

  return {
    checkHash,
  };
};

<template>
  <Modal
    ref="modal"
    v-model:open="modalOpen"
    class="bg-modal-overlay shadow-lvl-05"
    :click-to-close="false"
    max-width="1020px"
    :content-padding="false"
    :overlay="false"
    :center="true"
    anchor="top"
    :bgset="false"
    @closed="() => emit('close')"
  >
    <div
      class="border-01 bg-surface-lvl-00 flex h-[710px] max-h-full w-[454px] max-w-full flex-col overflow-hidden border-2"
    >
      <div class="flex h-12 items-center pr-2.5 pl-4">
        <p class="text-body-lg-heavy text-secondary">
          {{ t("edit_sender_address", { mode: t(reverseMode).toLocaleLowerCase() }) }}
        </p>
        <Button
          class="text-foreground-tertiary ml-auto"
          type="button"
          icon-only
          :variant="ButtonVariant.Tertiary"
          @click="emit('close')"
        >
          <Icon class="cursor-pointer" src="close" />
        </Button>
      </div>
      <div class="p-1.5">
        <div class="p-2.5">
          <p class="text-body-default text-secondary mb-4">
            {{ t("edit_sender_address_text", { mode: t(reverseMode).toLocaleLowerCase() }) }}
          </p>
          <div class="mt-4 grid grid-cols-6 gap-4">
            <InputAddressGoogle
              v-model="dataCopy.name"
              class="col-span-3"
              :label="t('company_name')"
              :placeholder="t('name')"
              :country="dataCopy.country_code"
              @select="fillFromLocation"
            />
            <InputText
              v-model="dataCopy.attention"
              class="col-span-3"
              :label="t('attention_name')"
              :placeholder="t('attention_name')"
            />
            <InputAddressGoogle
              v-model="dataCopy.street1"
              class="col-span-3"
              :label="t('street1')"
              :placeholder="t('address')"
              :country="dataCopy.country_code"
              @select="fillFromLocation"
            />
            <InputText
              v-model="dataCopy.street2"
              class="col-span-3"
              :label="t('street2_optional')"
              :placeholder="t('address')"
            />
            <div class="col-span-6 flex justify-between gap-3">
              <InputText
                v-model="dataCopy.zip_code"
                class="w-full max-w-[120px]"
                :label="t('zip_code')"
                :placeholder="t('zip_code')"
                @update:model-value="autocity"
              />
              <InputText v-model="dataCopy.city" class="w-full" :label="t('city')" :placeholder="t('city')" />
              <DropdownSelectCountriesWithPrices v-model="dataCopy.country_code" class="w-full" :label="t('country')" />
            </div>
            <InputText v-model="dataCopy.email" class="col-span-3" :label="t('email')" :placeholder="t('email')" />
            <InputPhone
              class="col-span-3"
              :values="phoneValues"
              :label="t('phone')"
              :placeholder="t('phone')"
              @update:model-value="(val) => updatePhone(val)"
            />
          </div>
        </div>
        <Divider class="mt-[11px] mb-[11px]" :text="t('or').toLocaleLowerCase()" />
        <div class="p-2.5">
          <p class="text-body-default text-tertiary mb-4">
            {{ t("select_previous_address", { mode: t(reverseMode).toLocaleLowerCase() }) }}
          </p>
          <div v-if="lastUsedAddresses.length !== 0">
            <div
              v-for="address in lastUsedAddresses"
              :key="address.street1"
              class="hover:bg-02 flex h-9 cursor-pointer items-center gap-2 rounded-xl px-4 transition duration-300"
              @click="setAddress(address)"
            >
              <Icon class="text-foreground-secondary" src="clock_counter_clockwise" />
              <p class="text-body-default-heavy text-secondary truncate">
                {{ address.name }}, <span v-if="address.attention">{{ address.attention }}, </span
                >{{ address.street1 }}, {{ address.zip_code }}, {{ address.city }},
                {{ getCountryName(address.country) }}
              </p>
              <Icon class="text-foreground-secondary" src="arrow_right" />
            </div>
          </div>
          <div v-else>
            <p class="text-body-default text-tertiary">{{ t("no_previous_address") }}</p>
          </div>
        </div>
      </div>
      <div class="mt-auto flex justify-end gap-3 p-4">
        <Button type="button" @click="emit('close')">{{ t("undo") }}</Button>
        <Button type="button" :variant="ButtonVariant.Primary" @click="overWriteAddress()">{{
          t("save_and_overwrite_address")
        }}</Button>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import type { GoogleAddressData } from "~/components/Input/AddressGoogle.vue";

  const props = defineProps<{
    open: boolean;
    mode: string;
    values: any;
  }>();

  const modalOpen = ref(props.open);

  const lastUsedAddresses = ref([]);

  const { t } = useI18n();

  const emit = defineEmits(["close", "updateAddress"]);

  const reverseMode = computed(() => {
    return props.mode === "sender" ? "receiver" : "sender";
  });

  const valueRef = ref(props.values[reverseMode.value]);
  const phoneValues = ref({
    prefix: "",
    phone_number: "",
  });

  onMounted(() => {
    if (valueRef.value) {
      phoneValues.value = {
        prefix: valueRef.value.phone_prefix,
        phone_number: valueRef.value.phone,
      };
    }
  });

  onBeforeMount(() => {
    const savedAddresses = localStorage.getItem("savedAddresses");
    if (savedAddresses) {
      lastUsedAddresses.value = JSON.parse(savedAddresses);
    }
  });

  const dataCopy = ref({ ...props.values[reverseMode.value], country_code: props.values[reverseMode.value]?.country });

  const debounceTimeout = ref(null);

  const debounce = () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(() => {
      autocity();
    }, 500); // delay for half second
  };

  watch(
    () => dataCopy.value?.zip_code,
    (value) => {
      if (value) {
        debounce();
      }
    }
  );

  const updatePhone = (val) => {
    dataCopy.value.phone = val.phone_number;
    dataCopy.value.phone_prefix = val.prefix;
  };

  const autocity = () => {
    if (!dataCopy.value.zip_code || !dataCopy.value.country) return;

    homeFetch(`/autocity/?zip_code=${dataCopy.value?.zip_code}&country=${dataCopy.value?.country}`).then((response) => {
      if (response.data.autocity.length > 0) {
        dataCopy.value.city = response.data.autocity[0].city;
      }
    });
  };

  const setAddress = (address) => {
    dataCopy.value = { ...address };
    phoneValues.value = {
      prefix: address.phone_prefix,
      phone_number: address.phone,
    };
  };

  const overWriteAddress = () => {
    let savedAddresses = localStorage.getItem("savedAddresses");
    if (!savedAddresses) {
      savedAddresses = "[]";
    }
    if (!savedAddresses.includes(JSON.stringify(dataCopy.value))) {
      const addresses = JSON.parse(savedAddresses);
      if (addresses.length == 3) {
        addresses.shift();
      }
      addresses.push(dataCopy.value);
      localStorage.setItem("savedAddresses", JSON.stringify(addresses));
    }
    emit("updateAddress", dataCopy.value);
    emit("close");
  };

  const fillFromLocation = (data?: GoogleAddressData) => {
    dataCopy.value.name = data?.businessStatus ? data.displayName?.text : undefined;
    dataCopy.value.city = data?.geoLocation.city;
    dataCopy.value.country = data?.geoLocation.country;
    dataCopy.value.street1 = data?.geoLocation.address_line1;
    dataCopy.value.zip_code = data?.geoLocation.postcode;
  };
</script>

<template>
  <div class="flex items-center gap-2">
    <div class="text-body-sm text-quarterary">{{ t("preview_checkout_in") }}</div>
    <DropdownSelect
      v-model="previewCountry"
      trigger-type="button"
      :trigger-button-variant="ButtonVariant.Tertiary"
      size="small"
      :options="markets"
      :readonly="markets.length <= 1"
    />
  </div>
  <div class="flex flex-col gap-2.5">
    <div v-if="!activeShippingMethodsForCountry.length">
      <div class="text-body-default-heavy text-tertiary">{{ t("no_shipping_methods_for_country") }}</div>
    </div>
    <template v-for="(sm, idx) in activeShippingMethodsForCountry" :key="sm.cps">
      <div class="flex w-full items-center gap-3">
        <div class="flex w-full min-w-0 items-center gap-3">
          <Avatar
            size="xs"
            :entity="{
              name: sm.name,
              image: getCarrierLogoUrl(sm.carrier),
            }"
          />
          <div class="flex w-full min-w-0 flex-col">
            <div class="text-body-lg-heavy flex items-center gap-3 overflow-hidden text-ellipsis whitespace-nowrap">
              <div>{{ sm.name }}</div>
              <div v-if="sm.label">
                <BadgeChip type="bold"> {{ sm.label }} </BadgeChip>
              </div>
            </div>
            <div class="text-body-sm text-tertiary">{{ sm.description }}</div>
          </div>
        </div>
        <div class="flex flex-nowrap gap-3 text-nowrap">
          <div class="text-body-default-heavy">{{ useFormatPrice(getPriceFromShippingMethod(sm), "auto", "") }}</div>
        </div>
      </div>

      <Divider v-if="idx < activeShippingMethodsForCountry.length - 1" :spacing="false" />
    </template>
  </div>
</template>

<script setup lang="ts">
  import { countryFilter } from "./SimpleRuleBuilder.vue";

  const props = withDefaults(
    defineProps<{
      markets: any[];
      shippingMethods: any[];
    }>(),
    {
      markets: () => [],
      shippingMethods: () => [],
    }
  );

  const { t } = useI18n();

  const previewCountry = ref(props.markets[0].value);

  const activeShippingMethodsForCountry = computed(() => {
    return props.shippingMethods
      .filter((sm: any) => sm.active && countryFilter(sm, previewCountry.value))
      .sort((a, b) => getPriceFromShippingMethod(a) - getPriceFromShippingMethod(b));
  });

  const getPriceFromShippingMethod = (shippingMethod: ICheckoutShippingMethod) => {
    return shippingMethod?.rules?.[0]?.price || 0;
  };

  const setPreviewCountry = (country: string) => {
    previewCountry.value = country;
  };

  defineExpose({
    setPreviewCountry,
  });
</script>

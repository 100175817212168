<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <GlobalLoader />
  <Toast />
  <template v-if="isUserLoggedIn">
    <BoardingModal />
    <NewShipmentWizard />
    <FormWizard />
    <InviteUsersWizard />
    <PrintWizard />
    <NewTicketWizard />

    <ClientOnly>
      <WorkspaceSettingsModal />
      <UserSettingsModal />
      <ModalLegal
        v-if="hasPendingLegal"
        :pending-document="pendingDocument"
        :loading="documentsLoading"
        @update-accept="(val) => acceptDocument(val)"
      />
      <ModalPresentation v-else-if="!isExternalAccess" />
      <DialogModal />
      <GlobalSearch />
    </ClientOnly>
  </template>
</template>
<script setup lang="ts">
  import "@fontsource-variable/dm-sans";

  const { isUserLoggedIn, isExternalAccess } = useAuth();
  const workspaceStore = useWorkspacesStore();
  const { setWorkspace } = workspaceStore;
  const { workspaces, hasMultipleWorkspaces } = storeToRefs(workspaceStore);
  const { acceptDocument, hasPendingLegal, pendingDocument, documentsLoading } = useLegalDocuments();
  const { checkHash } = useHashModals();
  const { fetchServices } = useServicesStore();
  const { getLegalDocuments } = useLegalDocuments();
  const { setupBaseListeners } = useEcho();

  //add current os to the body class for styling ie. ios, android, windows, mac
  onMounted(() => {
    if (import.meta.client) {
      document.body.classList.add(getOS().toLowerCase());
      checkHash();
    }
  });

  EventBus.$on("workspace-changed", () => {
    Promise.all([fetchServices(), getLegalDocuments()]);
    setTimeout(() => {
      setupBaseListeners();
    }, 1000);
  });

  const onKeyPress = (e: KeyboardEvent) => {
    if (!/\d/.test(e.key)) return;
    if (!hasMultipleWorkspaces.value) return;
    if (e.key === "0") return;

    //if development environment, use cmd+k to open the modal
    if (getOS() === "Mac") {
      if (e.metaKey) {
        setWorkspace(workspaces.value[parseInt(e.key) - 1]);
        e.preventDefault();
      }
    } else {
      if (e.ctrlKey) {
        setWorkspace(workspaces.value[parseInt(e.key) - 1]);
        e.preventDefault();
      }
    }
  };

  watch(
    hasMultipleWorkspaces,
    (value) => {
      if (value) {
        document.addEventListener("keydown", onKeyPress);
      } else {
        document.removeEventListener("keydown", onKeyPress);
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", onKeyPress);
  });

  useRouter().afterEach(() => {
    checkHash();
  });
</script>
<style>
  html,
  body,
  #__nuxt,
  #__layout {
    height: 100% !important;
    width: 100% !important;
  }
</style>

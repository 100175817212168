<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SettingsSection :title="t('user_settings.security.password.title')">
      <div class="flex w-full flex-col justify-between gap-3">
        <div class="flex flex-col gap-3">
          <InputText
            v-model="passwordResetBody.currentPassword"
            class="w-full"
            type="password"
            :label="t('current_password')"
            :placeholder="t('current_password')"
          />
          <InputPassword
            ref="passwordInput"
            v-model="passwordResetBody.newPassword"
            class="w-full"
            :label="t('new_password')"
            :placeholder="t('new_password')"
          />
          <InputText
            v-model="passwordResetBody.confirmPassword"
            type="password"
            :label="t('confirm_password')"
            :placeholder="t('confirm_password')"
            :error="passwordMatches ? '' : t('password_does_not_match')"
          />
          <div v-if="passwordMatches && isFullfilled && passwordResetBody.currentPassword" class="mt-4">
            <Button :loading="loading" :variant="ButtonVariant.Destructive" @click="updatePassword">
              {{ t("update_password") }}
            </Button>
          </div>
        </div>
      </div>
    </SettingsSection>

    <SettingsSection
      :title="t('user_settings.security.sso.title')"
      :description="t('user_settings.security.sso.description')"
    >
      <div class="flex w-full flex-col gap-3">
        <div class="flex gap-3">
          <Button
            v-for="[provider, value] in Object.entries(providers)"
            :key="provider"
            @click="value ? disconnectProvider(provider) : connectProvider(provider)"
          >
            <template #prefix>
              <Icon :src="`custom/services/${provider}`" filled />
            </template>
            {{
              value
                ? t("sso.disconnect_provider", { provider: t(`sso.providers.${provider}`) })
                : t("sso.connect_provider", { provider: t(`sso.providers.${provider}`) })
            }}
          </Button>
        </div>
      </div>
    </SettingsSection>
  </div>
  <SettingsNotificationWrapper />
</template>

<script setup lang="ts">
  import _ from "lodash";
  import type { ISettingsFlow } from "@/types/settings";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
      loading: false,
    }
  );
  const { t } = useI18n();
  const passwordInput = ref(null);
  const passwordResetBody = ref({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const providers = ref<Record<string, boolean>>({});

  const isFullfilled = computed(() => passwordInput.value?.isFullfilled);

  const updatePassword = () => {
    $confirm({
      message: t("confirm_update_password"),
      confirmText: t("update_password"),
      cancelText: t("cancel"),
      destructive: true,
      onCancel: () => {
        passwordResetBody.value = {
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        };
      },
      onConfirm: () => {
        setLoading(true);
        homeFetch("settings/security/password", {
          method: "PUT",
          body: {
            current_password: passwordResetBody.value.currentPassword,
            new_password: passwordResetBody.value.newPassword,
            new_password_confirmation: passwordResetBody.value.confirmPassword,
          },
        })
          .then(() => {
            $toast.add({
              title: t("password_updated"),
            });

            passwordResetBody.value = {
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            };
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const passwordMatches = computed(() => {
    return (
      passwordResetBody.value.newPassword === passwordResetBody.value.confirmPassword &&
      passwordResetBody.value.newPassword.length > 0
    );
  });

  onMounted(() => {
    goToCurrentHeader();
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  const getProviders = () => {
    setLoading(true);
    homeFetch<Record<string, boolean>>("settings/sso/providers")
      .then((r) => {
        providers.value = r.data || {};
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const connectProvider = (provider: string) => {
    setLoading(true);
    homeFetch(`settings/sso/${provider}`)
      .then((r) => {
        window.location.href = r.data.redirect_url;
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disconnectProvider = (provider: string) => {
    window.$confirm({
      message: t("sso.providers.confirm_disconnect", { provider: t(`sso.providers.${provider}`) }),
      confirmText: t("sso.disconnect"),
      cancelText: t("cancel"),
      destructive: true,
      onConfirm: () => {
        setLoading(true);
        homeFetch(`settings/sso/${provider}`, {
          method: "DELETE",
        })
          .then(() => {
            $toast.add({
              type: "success",
              title: t("sso.provider_disconnected", { provider: t(`sso.providers.${provider}`) }),
            });
            return getProviders();
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  onMounted(() => {
    getProviders();

    const route = useRoute();
    if (route.query?.sso_provider) {
      $toast.add({
        type: "success",
        title: t("sso.provider_connected", { provider: t(`sso.providers.${route.query.sso_provider}`) }),
      });

      //remove query params from url but keep fragment
      const url = new URL(window.location.href);
      url.search = "";
      window.history.replaceState({}, document.title, url.toString());
    }
  });
</script>

<script lang="ts">
  export const useSecuritySettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/LockSimple";
    const title = t("user_settings.security.title");
    const pageTitle = t("user_settings.security.page_title");
    const key = "security";
    const headers = [t("user_settings.security.password.title"), t("user_settings.security.sso.title")];
    const condition = computed(() => true);

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
      condition,
    };
  };
</script>

import { defineStore } from "pinia";
import type { IServices } from "~/types/services";

export const useServicesStore = defineStore(
  "Services",
  () => {
    const services = ref<Partial<IServices>>({});
    const { accountServices } = storeToRefs(useWorkspacesStore());
    const setServices = (s: IServices) => {
      services.value = s;
    };

    const setService = (name: keyof IServices, service: any) => {
      services.value[name] = service;
    };

    const getService = (name: keyof IServices) => {
      return services.value[name];
    };

    const fetchServices = () => {
      return homeFetch("get-services").then((response) => {
        setServices(response.data);
      });
    };

    const clearServices = () => {
      services.value = {};
    };

    const hasService = (service: keyof IServices | "transfer" | string): boolean => {
      if (service === "transfer") service = "transfer_enabled";
      const { account } = storeToRefs(useWorkspacesStore());
      if (!services.value[service as keyof IServices]) {
        return !!account.value?.services.includes(service);
      }

      return !!services.value[service];
    };

    return {
      services,
      setServices,
      setService,
      getService,
      fetchServices,
      clearServices,
      hasService,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
);

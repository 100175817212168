<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SettingsSection :title="t('email')">
      <div class="flex flex-col gap-3">
        <TickerCheckbox
          v-model="marketingConsent"
          :label="t('user_settings.notifications.confirm_marketing')"
          @change="onMarketingConsentChange"
        />
        <p
          class="text-body-sm text-tertiary ps-7.5"
          v-html="
            t('user_settings.notifications.confirm_marketing_description', {
              link: 'https://homerunner.com/privacy-policy/',
            })
          "
        />
      </div>
    </SettingsSection>
  </div>
  <SettingsNotificationWrapper />
</template>

<script setup lang="ts">
  import _ from "lodash";
  import type { ISettingsFlow } from "@/types/settings";
  import type { IUser } from "~/types/auth";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();
  const information = ref<IUser>({} as IUser);

  const marketingConsent = computed({
    get: () => !!information.value.marketing_consent,
    set: (value: boolean) => {
      information.value = {
        ...information.value,
        marketing_consent: value ? 1 : 0,
      };
    },
  });

  const fetchInformation = async () => {
    setLoading(true);
    return homeFetch("settings/profile")
      .then((response) => {
        if (response?.data) {
          information.value = { ...response.data?.profile } as IUser;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onMarketingConsentChange = () => {
    updateInformation();
  };

  const updateInformation = () => {
    setLoading(true);
    const marketing_consent = marketingConsent.value ? 1 : 0;

    homeFetch("settings/profile/marketing", {
      method: "PUT",
      body: {
        consent: marketing_consent,
      },
    }).finally(() => {
      setLoading(false);
    });
  };

  onMounted(() => {
    fetchInformation().then(() => {
      goToCurrentHeader();
    });
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useNotificationSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/Notification";
    const title = t("user_settings.notifications.title");
    const pageTitle = t("user_settings.notifications.title");
    const key = "notifications";
    const headers = [t("email")];
    const condition = true;

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
      condition,
    };
  };
</script>

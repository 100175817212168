<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SettingsSection :title="t('user_settings.profile.picture.title')">
      <div class="flex w-full flex-wrap gap-3">
        <AvatarUpload
          class="w-full"
          :entity="{
            name: userName,
            image: userPicture,
          }"
          :type="AvatarType.User"
          random-color
          @uploaded="updateUserPicture"
          @removed="updateUserPicture"
        />
      </div>
    </SettingsSection>
    <Divider />
    <SettingsSection :title="t('user_settings.profile.personal_information.title')">
      <div class="flex w-full flex-col justify-between gap-3">
        <div class="grid auto-cols-fr grid-cols-2 gap-3">
          <InputText v-model="firstName" class="col-span-1 w-full" :label="t('first_name')" />
          <InputText v-model="lastName" class="col-span-1 w-full" :label="t('last_name')" />
          <InputText v-model="information.phone" class="col-span-1" :label="t('phone_number')" />
          <InputText v-model="information.email" class="col-span-1" :label="t('email')" />
        </div>
      </div>
    </SettingsSection>
    <Divider />
    <Timezone />
    <Divider />
    <SettingsSection
      :title="t('weight_unit', 99)"
      :description="t('settings.organisation.workspace.weight_unit.description')"
    >
      <div class="grid auto-cols-fr grid-cols-2 gap-3 @lg:grid-cols-4">
        <TickerRadio :selected="isWeightUnit('g')" rounded framed @click="() => setWeightUnit('g')">
          {{ t("gram") }} (g)
        </TickerRadio>
        <TickerRadio :selected="isWeightUnit('kg')" rounded framed @click="() => setWeightUnit('kg')">
          {{ t("kilogram") }} (kg)
        </TickerRadio>
        <TickerRadio :selected="isWeightUnit('lb')" rounded framed @click="() => setWeightUnit('lb')">
          {{ t("pound") }} (lb)
        </TickerRadio>
        <TickerRadio :selected="isWeightUnit('oz')" rounded framed @click="() => setWeightUnit('oz')">
          {{ t("ounce") }} (oz)
        </TickerRadio>
      </div>
    </SettingsSection>
  </div>
  <SettingsNotificationWrapper />
</template>

<script setup lang="ts">
  import _ from "lodash";
  import type { IUser } from "~/types/auth";
  import type { ISettingsFlow } from "~/types/settings";

  import Timezone from "./Subflow/Timezone.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();
  const information = ref<IUser>({} as IUser);
  let initialInformation = {} as IUser;
  const workspaceStore = useWorkspacesStore();
  const { fetchWorkspaces, updateUserPicture } = workspaceStore;
  const { userName, userPicture } = storeToRefs(workspaceStore);

  // Information
  const firstName = computed({
    get: () => information.value?.name?.split(" ")[0] || "",
    set: (value: string) => {
      const first = value;
      const last = information.value?.name?.split(" ")[1] || "";
      const combined = `${first} ${last}`.trim();
      information.value = {
        ...information.value,
        name: combined,
      };
    },
  });

  const lastName = computed({
    get: () => information.value?.name?.split(" ")[1] || "",
    set: (value: string) => {
      const first = information.value?.name?.split(" ")[0];
      const last = value;
      const combined = `${first} ${last}`.trim();

      information.value = {
        ...information.value,
        name: combined,
      };
    },
  });

  const fetchInformation = async () => {
    setLoading(true);
    return homeFetch("settings/profile")
      .then((response) => {
        if (response?.data) {
          information.value = { ...response.data?.profile } as IProfile;
          initialInformation = _.cloneDeep(information.value);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateInformation = () => {
    setLoading(true);
    homeFetch("settings/profile", {
      method: "PUT",
      body: information.value,
    })
      .then(() => fetchWorkspaces(false))
      .then(() => {
        initialInformation = _.cloneDeep(information.value);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetInformation = () => {
    information.value = _.cloneDeep(initialInformation);
  };

  const informationHasChanged = computed(() => !_.isEqual(information.value, initialInformation));

  // Weight unit
  const weightUnit = ref<string>(unref(usePreferredWeightUnit()));
  const isWeightUnit = (unit: string) => weightUnit.value === unit;
  const setWeightUnit = (unit: string) => {
    weightUnit.value = unit;
  };
  const weightUnitHasChanged = computed(() => weightUnit.value !== usePreferredWeightUnit());
  const resetWeightUnit = () => {
    weightUnit.value = unref(usePreferredWeightUnit());
  };

  // General
  const hasChanges = computed(() => {
    return informationHasChanged.value || weightUnitHasChanged.value;
  });

  watch(hasChanges, (newVal) => {
    if (!newVal) {
      $toast.remove("profile_settings");
      return;
    } else
      $toast.add({
        duration: 0,
        id: "profile_settings",
        group: "profile",
        title: t("unsaved_changes"),
        target: "#settings-modal .notification-wrapper",
        icon: null,
        actions: [
          {
            text: t("undo"),
            handler: () => {
              resetWeightUnit();
              resetInformation();
            },
          },
          {
            text: t("save_changes"),
            handler: () => save(),
          },
        ],
      });
  });

  const save = () => {
    $confirm({
      message: t("confirm_save_changes"),
      confirmText: t("save_changes"),
      cancelText: t("cancel"),
      onConfirm: () => {
        if (informationHasChanged.value) {
          updateInformation();
        }
        if (weightUnitHasChanged.value) {
          updateWeightUnit(weightUnit.value);
        }
      },
    });
  };

  onMounted(() => {
    fetchInformation().then(() => {
      goToCurrentHeader();
    });
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useProfileSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/UserCircle";
    const title = t("user_settings.profile.title");
    const pageTitle = t("user_settings.profile.page_title");
    const key = "profile";
    const headers = [t("user_settings.profile.personal_information.title"), t("user_settings.profile.language")];
    const condition = true;

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
      condition,
    };
  };
</script>

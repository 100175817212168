import { RouteName } from "~/types/routes";

export default (key: string) => {
  const { hasService } = useServicesStore();
  const { accountIsBusiness, isDFM } = useWorkspacesStore();
  const { hasCapability } = useCapabilities();
  switch (key) {
    case RouteName.shipments:
    case RouteName.shipments_details:
      return hasCapability("shipments");
    case RouteName.shipments_label:
      return hasCapability("shipments.action.label.download");
    case RouteName.shipments_import:
    case RouteName.shipments_import_list:
      return hasCapability("shipments.import");
    case RouteName.invoices:
      return hasCapability("account.invoices") || hasCapability("account.invoices.edit");
    case RouteName.documents:
      return hasCapability("account.documents") || hasCapability("account.documents.edit");
    case RouteName.prices:
      return hasCapability("account.prices") || hasCapability("account.prices.edit");
    case RouteName.returns:
      return accountIsBusiness && hasCapability("service:returns");
    case RouteName.tickets:
    case RouteName.tickets_details:
      return hasService("tickets") && hasCapability("service:ticket");
    case RouteName.sortings:
      return accountIsBusiness && hasService("transfer") && hasCapability("service:sorting");
    case RouteName.click_collect:
      return hasService("clickcollect");
    case RouteName.purchase_orders:
    case RouteName.purchase_orders_details:
    case RouteName.seafreight:
    case RouteName.seafreight_details:
    case RouteName.containers:
    case RouteName.containers_details:
      return hasService("container");
    case RouteName.checkouts_analytics:
      return hasService("checkout_analytics");
    default:
      return true;
  }
};

<template>
  <Modal
    ref="modal"
    v-model:open="open"
    class="shadow-lvl-05"
    :click-to-close="false"
    max-width="1020px"
    :content-padding="false"
    :center="true"
    anchor="top"
    :overlay="false"
    @closed="() => emit('close')"
  >
    <div v-if="!portalCreated" class="h-[756px] max-h-full w-[1020px] max-w-full">
      <FormWizard
        ref="wizard"
        class="flex h-full w-full flex-col [&_.form-step]:mx-auto"
        :loading="loading"
        :validation-schema="validationSchema"
        show-progress
        :name="t('create_return_portal')"
        :step-names="stepNames"
        header-type="create"
        footer-type="create"
        :submit-button-text="t('create')"
        hide-title
        @submit="onSubmit"
        @exit="(emit('close'), (portalCreated = false))"
        @next="(val) => handleNext(val)"
      >
        <template #step-settings="{ schema }">
          <Settings
            :information="information"
            :schema="schema"
            :values="values"
            :legacy="legacy"
            @update-address="(val) => setAddressValue(val)"
            @update-alt-address="(val) => setAltAddressValue(val)"
          />
        </template>
        <template #step-legacy="{ schema }">
          <Legacy :schema="schema" :values="values" />
        </template>
        <template #step-causes="{ schema }">
          <Causes :schema="schema" :values="values" />
        </template>
        <template #step-look="{ schema }">
          <Look :schema="schema" :values="values" :information="information" />
        </template>
        <template v-if="!legacy" #step-rules="{ schema }">
          <Rules :checkouts="checkouts" :schema="schema" :values="values" :information="information" />
        </template>
      </FormWizard>
    </div>
    <div v-if="portalCreated" class="flex h-[756px] max-h-full w-[1020px] max-w-full flex-col items-center">
      <div class="m-auto max-w-[540px]">
        <SuccessBlob />
        <p class="text-title-section text-secondary mb-2 w-full text-center">
          {{ t("return_portal_name", { name: createdName }) }}
        </p>
        <!--<p class="text-body-lg text-tertiary mb-4">{{ t("webshop_is_created_desc") }}</p>-->
        <Button
          class="mt-4 w-full"
          type="button"
          :variant="ButtonVariant.Default"
          @click="(emit('close'), (portalCreated = false))"
          >{{ t("close") }}</Button
        >
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import Causes from "./partials/Causes.vue";
  import Legacy from "./partials/Legacy.vue";
  import Look from "./partials/Look.vue";
  import Rules from "./partials/Rules.vue";
  import Settings from "./partials/Settings.vue";

  const open = defineModel<boolean>("open", { default: false });
  const loading = ref(false);
  const legacy = ref(false);
  const portalCreated = ref(false);
  const yup = useYup();
  const { t } = useI18n();
  const emit = defineEmits(["close", "update"]);
  const createdName = ref("");
  const props = defineProps<{
    portal: object | null;
    information: any;
  }>();
  const { checkoutsForSelect } = useCheckouts();
  const wizard = ref(null);
  const values = computed(() => wizard.value?.values);
  const checkouts = ref([]);
  onMounted(() => {
    checkoutsForSelect().then((checkoutsSelect) => {
      checkouts.value = checkoutsSelect;
    });
  });
  const validationSchema = computed(() => {
    const schema: Record<string, any>[] = [
      yup
        .object({
          name: yup.string().required(),
          logo: yup.string().optional().default(""),
          address: yup.string().default("SHIPPING"),
          alternative_address: yup.boolean().default(false),
          settings: yup.object({
            alternative_address: yup.object({
              attention: yup.string().nullable(),
              city: yup.string().nullable(),
              company: yup.string().nullable(),
              country: yup.string().nullable(),
              notification_email: yup.string().nullable(),
              street: yup.string().nullable(),
              zip_code: yup.string().nullable(),
              telephone: yup.string().nullable(),
            }),
          }),
        })
        .meta({ continueButtonText: t("continue"), title: t("portal_settings"), key: "settings" }),
      yup
        .object({
          price: yup.string().required().default("0"),
          product: yup.string().required().default(""),
          country: yup.string().required().default("DK"),
          currency: yup.string().required().default("DKK"),
        })
        .meta({ continueButtonText: t("continue"), title: t("set_return_portal"), key: "legacy", autoskip: true }),
      yup
        .object({
          settings: yup.object({
            causes: yup.array().default([]),
          }),
        })
        .meta({ continueButtonText: t("continue"), title: t("return_causes"), key: "causes" }),
      yup
        .object({
          title: yup.string().required().default("Returportal"),
          description: yup
            .string()
            .required()
            .default(
              "<p>Her kan du ganske let købe en pakkelabel til brug ved returnering af varer, som er købt hos Homerunner. <br> Returfragten koster 0 kr. og du kan betale med kreditkort. Når betalingen er gennemført, vil du få vist kvitteringsside, og her kan du hente og udskrive din returlabel. Returlabelen vil også blive sendt som vedhæftet pdf til den e-mailadresse, som du har oplyst. Du kan udskrive returlabelen på en almindelig A4-printer. <br> Herefter sætter du labelen forsvarligt fast på pakken uden at dække tekst og stregkode. Så er du klar til at aflevere pakken til Bring på et posthus, i en postbutik eller en pakkeboks.</p>"
            ),
          settings: yup.object({
            order_gate_title: yup.string().default(null).nullable(),
            order_gate_description: yup.string().default(null).nullable(),
            order_gate_limit: yup.number().default(100),
          }),
        })
        .meta({ continueButtonText: t("continue"), title: t("look"), key: "look" }),
      yup
        .object({
          settings: yup.object({ checkout_id: yup.number().required().default(null) }),
        })
        .meta({ title: t("checkout"), key: "rules", continueButton: t("continue"), autoskip: false }),
    ];

    return schema;
  });

  const handleNext = async (val: number) => {
    if (val === 4 && legacy.value) {
      onSubmit(values.value);
    }
  };

  watch(
    () => open.value,
    (newValue) => {
      legacy.value = false;
      validationSchema.value[1].spec.meta.autoskip = true;
      validationSchema.value[3].spec.meta.continueButtonText = t("continue");
      if (newValue) {
        if (props.portal) {
          if (!props.portal.settings.smartcheckout) {
            legacy.value = true;
            validationSchema.value[1].spec.meta.autoskip = false;
            validationSchema.value[3].spec.meta.continueButtonText = t("update");
          }
          setTimeout(() => {
            if (!props.portal?.settings?.smartcheckout) {
              wizard.value?.setValues({
                price: props.portal.price,
                product: props.portal.product,
                country: props.portal.country,
                currency: props.portal.currency,
              });
            }
            wizard.value?.setValues({
              name: props.portal.name,
              logo: props.portal.logo || "",
              address: props.portal.settings.alternative_address.city ? "ALTERNATIVE" : "SHIPPING",
              alternative_address: props.portal.settings.alternative_address.city ? true : false,
              title: props.portal.title,
              description: props.portal.description,
              settings: {
                checkout_id: props.portal.settings?.checkout?.id || null,
                order_gate: props.portal.settings.order_gate,
                private: props.portal.settings.private,
                public: props.portal.settings.public,
                order_number: props.portal.settings.order_number,
                causes: props.portal.settings.causes,
                css_style: props.portal.settings.css_style,
                smartcheckout: props.portal.settings.smartcheckout,
                reference_key: props.portal.settings.reference_key,
                order_gate_title: props.portal.settings.order_gate_title,
                order_gate_description: props.portal.settings.order_gate_description,
                order_gate_age_limit: props.portal.settings.order_gate_age_limit,
              },
            });
            if (props.portal.settings?.alternative_address?.city) {
              wizard.value?.setValues({
                settings: {
                  alternative_address: {
                    attention: props.portal.settings.alternative_address.attention,
                    city: props.portal.settings.alternative_address.city,
                    company: props.portal.settings.alternative_address.company,
                    country: props.portal.settings.alternative_address.country,
                    notification_email: props.portal.settings.alternative_address.notification_email,
                    street: props.portal.settings.alternative_address.street,
                    zip_code: props.portal.settings.alternative_address.zip_code,
                    telephone: props.portal.settings.alternative_address.telephone,
                  },
                },
              });
            }
          }, 50);
        }
      }
    }
  );

  const stepNames = computed(() => validationSchema.value.map((schema) => schema.describe().meta?.title));

  const setAddressValue = (val: string) => {
    wizard.value?.setValues({ address: val });
  };

  const setAltAddressValue = (val: boolean) => {
    wizard.value?.setValues({ alternative_address: val });
  };

  const onSubmit = (formData) => {
    const data = { ...formData };
    data.customer_url = null;
    if (!data.settings.causes) {
      data.settings.causes = [];
    }
    if (props.portal) {
      data.uid = props.portal.uid;
      return homeFetch(`workspace/return-portals/`, {
        method: "PUT",
        body: {
          ...data,
        },
      }).then(() => {
        $toast.add({
          title: t("portal_updated"),
        });
        emit("update");
        emit("close");
      });
    }
    createdName.value = data.name;
    data.settings.order_number = false;
    data.settings.private = false;
    data.settings.smartcheckout = true;
    data.settings.public = false;
    data.settings.private = false;
    data.settings.order_gate = true;
    data.country = "DK";
    data.price = 0;
    data.product = "gls_business_return_labelless";
    loading.value = true;
    return homeFetch("workspace/return-portals", {
      method: "POST",
      body: {
        ...data,
      },
    })
      .then(() => {
        $toast.add({
          title: t("portal_created"),
        });
        portalCreated.value = true;
        emit("update");
      })
      .finally(() => {
        loading.value = false;
      });
  };
</script>

<template>
  <div
    :key="workspace.subdomain || workspace.account.id"
    class="flex items-center gap-2.5 self-stretch rounded-md bg-white px-2 py-1.5"
    :class="{ 'cursor-pointer': hoverEffect }"
    @click="handleClick"
  >
    <Button
      v-if="draggable"
      class="cursor-grab rounded-lg"
      data-swapy-handle
      :size="ComponentSize.sm"
      :variant="ButtonVariant.Tertiary"
    >
      <Icon src="dots_nine" class="drag-handle rounded-lg p-3" />
    </Button>
    <Avatar
      size="xs"
      :entity="{
        name: workspace.account.alias || workspace.account.name,
        image: workspace.account.logo,
      }"
      random-color
      :type="AvatarType.Webshop"
    />
    <div class="text-primary mr-auto flex flex-col justify-center overflow-hidden text-sm font-medium">
      <span class="truncate">{{ workspace.account.alias || workspace.account.name }}</span>
      <span
        v-if="workspace.account.business"
        class="text-tertiary truncate text-xs text-nowrap text-ellipsis"
        :title="buildDomainForWorkspace(workspace)"
      >
        {{ buildDomainForWorkspace(workspace) }}
      </span>
    </div>

    <slot name="actions" />

    <div
      class="bg-01 text-tertiary text-body-default-heavy flex h-6 items-center justify-center rounded-sm px-1 py-0.5"
    >
      {{ isMac ? "⌘" : "ctrl" }}+{{ index + 1 }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  import type { IWorkspace } from "~/types/auth";

  const props = withDefaults(
    defineProps<{
      workspace: IWorkspace;
      index: number;
      draggable?: boolean;
      hoverEffect?: boolean;
      clickable?: boolean;
    }>(),
    {
      draggable: false,
      hoverEffect: true,
      clickable: true,
    }
  );

  const isMac = getOS() === "Mac";

  const { setWorkspace, buildDomainForWorkspace } = useWorkspacesStore();

  function handleClick() {
    if (props.clickable) {
      setWorkspace(props.workspace);
    }
  }
</script>

<template>
  <SettingsModal :flows="allFlows" modal-context="preferences">
    <template #sidebar-top>
      <AvatarUser />
      <div class="text-primary mr-auto flex flex-col overflow-hidden text-sm font-medium">
        <span>{{ userName }}</span>
        <span class="text-tertiary text-xs text-nowrap text-ellipsis">{{ userEmail }}</span>
      </div>
    </template>
  </SettingsModal>
</template>

<script setup lang="ts">
  import AccessLog, { useAccessLogSettings } from "../flows/AccessLog.vue";
  import NotificationSettings, { useNotificationSettings } from "../flows/NotificationSettings.vue";
  import ProfileSettings, { useProfileSettings } from "../flows/ProfileSettings.vue";
  import SecuritySettings, { useSecuritySettings } from "../flows/SecuritySettings.vue";
  import WorkspaceAndRoles, { useWorkspaceAndRolesSettings } from "../flows/WorkspaceAndRoles.vue";

  defineOptions({
    name: "UserSettingsModal",
  });

  const { userName, userEmail } = storeToRefs(useWorkspacesStore());

  const allFlows = ref<ISettingsDefinition[]>([
    {
      key: "profile",
      component: markRaw(ProfileSettings),
      flow: useProfileSettings(),
    },
    {
      key: "security",
      component: markRaw(SecuritySettings),
      flow: useSecuritySettings(),
    },
    {
      key: "notifications",
      component: markRaw(NotificationSettings),
      flow: useNotificationSettings(),
    },
    {
      key: "workspaces_roles",
      component: markRaw(WorkspaceAndRoles),
      flow: useWorkspaceAndRolesSettings(),
    },
    {
      key: "access_log",
      component: markRaw(AccessLog),
      flow: useAccessLogSettings(),
    },
  ]);
</script>

<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SettingsSection
      :title="t('settings.organisation.workspace.logo.title')"
      :description="t('settings.organisation.workspace.logo.description')"
    >
      <div class="flex w-full flex-wrap gap-3">
        <AvatarUpload
          class="w-full"
          :entity="{
            name: accountName,
            image: accountLogo,
          }"
          :type="AvatarType.Webshop"
          @uploaded="updateAccountLogo"
          @removed="updateAccountLogo"
        />
      </div>
    </SettingsSection>
    <Divider />
    <SettingsSection
      :title="t('settings.organisation.workspace.name.title')"
      :description="t('settings.organisation.workspace.weight_unit.description')"
    >
      <div class="grid w-full grid-cols-2 gap-4">
        <InputText v-model="information.name" :label="t('organisation_name')" />
        <InputSubdomain v-model="information.subdomain" :placeholder="information.name" :label="t('domain')" />
        <InputText
          v-model="information.alias"
          :message="t('settings.organisation.workspace.alias.description')"
          :label="t('settings.organisation.workspace.alias.title')"
        /></div
    ></SettingsSection>
    <Divider />
    <SettingsSection
      :title="t('settings.organisation.workspace.address.title')"
      :description="t('settings.organisation.workspace.address.description')"
    >
      <div v-if="dfmAgreementNumber" class="grid grid-cols-2 gap-4">
        <InputText :model-value="dfmAgreementNumber" disabled :label="t('agreement_number')" />
      </div>
      <div v-if="shippingIndex !== -1" class="grid auto-cols-fr grid-cols-2 gap-3 @lg:grid-cols-4">
        <InputText
          v-model="information.contact_info[shippingIndex].name"
          class="col-span-2 w-full"
          :label="t('organisation_name')"
        />
        <InputText
          v-model="information.contact_info[shippingIndex].street1"
          class="col-span-2 w-full"
          :label="t('street_name_and_number')"
        />
        <div class="col-span-2 grid grid-cols-2 gap-3 @lg:col-span-4 @lg:grid-cols-4">
          <InputText
            v-model="information.contact_info[shippingIndex].zip_code"
            class="col-span-2 w-full @md:col-span-1"
            :label="t('zip_code')"
          />
          <InputText
            v-model="information.contact_info[shippingIndex].city"
            class="col-span-2 w-full @md:col-span-1"
            :label="t('city')"
          />
          <DropdownSelectCountry
            v-model="information.contact_info[shippingIndex].country_iso"
            class="col-span-2"
            :label="t('country')"
            trigger-type="input"
          />
        </div>
        <InputText
          v-model="information.contact_info[shippingIndex].phone"
          class="col-span-2"
          :label="t('phone_number')"
        />
        <InputText v-model="information.contact_info[shippingIndex].email" class="col-span-2" :label="t('email')" />

        <div class="col-span-2 @lg:col-span-4">
          <TickerCheckbox
            v-model="billingAndShippingSame"
            :label="t('settings.organisation.workspace.address.billing_and_shipping_same')"
          />
        </div></div
    ></SettingsSection>
    <SettingsSection
      v-if="!billingAndShippingSame && billingIndex !== -1"
      :title="t('settings.organisation.workspace.address.invoice.title')"
      ><div class="grid auto-cols-fr grid-cols-2 gap-3 @lg:grid-cols-4">
        <InputText
          v-model="information.contact_info[billingIndex].name"
          class="col-span-2 w-full"
          :label="t('organisation_name')"
        />
        <InputText
          v-model="information.contact_info[billingIndex].street1"
          class="col-span-2 w-full"
          :label="t('street_name_and_number')"
        />
        <div class="col-span-2 grid grid-cols-2 gap-3 @lg:col-span-4 @lg:grid-cols-4">
          <InputText
            v-model="information.contact_info[billingIndex].zip_code"
            class="col-span-2 w-full @md:col-span-1"
            :label="t('zip_code')"
          />
          <InputText
            v-model="information.contact_info[billingIndex].city"
            class="col-span-2 w-full @md:col-span-1"
            :label="t('city')"
          />
          <DropdownSelectCountry
            v-model="information.contact_info[billingIndex].country_iso"
            class="col-span-2"
            :label="t('country')"
          />
        </div>
        <InputText
          v-model="information.contact_info[billingIndex].phone"
          class="col-span-2"
          :label="t('phone_number')"
        />
        <InputText v-model="information.contact_info[billingIndex].email" class="col-span-2" :label="t('email')" />
      </div>
    </SettingsSection>
    <Divider />
    <SettingsSection
      :title="t('settings.organisation.workspace.customer_service.title')"
      :description="t('settings.organisation.workspace.customer_service.description')"
    >
      <div v-if="information.customer_support_info" class="grid grid-cols-2 gap-4">
        <InputText v-model="information.customer_support_info.email" :label="t('email')" />
        <InputText v-model="information.customer_support_info.phone" :label="t('phone_number')" />
      </div>
    </SettingsSection>
    <template v-if="useHasPageAccess(RouteName.sortings)">
      <Divider />
      <SettingsSection
        :title="t('pickup_interval')"
        :description="t('settings.organisation.workspace.pickup_interval.description')"
      >
        <div class="grid grid-cols-2 gap-6">
          <section class="flex w-max flex-col gap-3">
            <header>
              <h3 class="text-body-default-heavy">{{ t("latest_booking") }}</h3>
            </header>
            <template v-for="day in week" :key="day">
              <div v-if="findHours(day)" class="flex h-9 items-center">
                <p class="text-body-sm me-auto pe-8">{{ t(day) }} {{ t("o_clock") }}</p>
                <p v-if="!findHours(day)!.latest_booking" class="text-body-sm text-disabled">
                  {{ t("not_collected") }}
                </p>
                <InputTime v-else v-model="findHours(day)!.latest_booking" :minutes-interval="1" disabled />
              </div>
            </template>
          </section>
          <section class="flex w-max flex-col gap-3">
            <header>
              <h3 class="text-body-default-heavy">{{ t("warehouse_closing") }}</h3>
            </header>
            <template v-for="day in week" :key="day">
              <div v-if="findHours(day)" class="flex items-center">
                <p class="text-body-sm me-auto pe-8">{{ t(day) }} {{ t("o_clock") }}</p>
                <InputTime v-model="findHours(day)!.warehouse_closing" :error="errors[day] ? 'true' : undefined" />
              </div>
            </template>
          </section>
        </div>
      </SettingsSection>
      <Divider />
    </template>
  </div>
  <SettingsNotificationWrapper />
</template>

<script setup lang="ts">
  import { RouteName } from "~/types/routes";
  import _ from "lodash";

  export interface OrganisationInformation {
    account_id: null;
    alias?: string;
    business: boolean;
    contact_info: ContactInfo[];
    core_customer_id: number;
    created_at: Date;
    customer_support_info: CustomerSupportInfo;
    deleted_at: null;
    disabled_at: null;
    id: number;
    level: string;
    name: string;
    payment_method: string;
    subdomain: string;
    test_mode: number;
    updated_at: Date;
    vat: string;
  }

  export interface CustomerSupportInfo {
    phone: string;
    email: string;
  }

  export interface ContactInfo {
    id: number;
    title: null;
    name: string;
    phone_prefix: null;
    phone: string;
    email: string;
    street1: string;
    street2: null;
    zip_code: string;
    city: string;
    type: "BILLING" | "SHIPPING";
    country_id: number;
    country_iso: string;
    entity_type: string;
    entity_id: number;
    latitude: number;
    longitude: number;
    created_at: Date;
    updated_at: Date;
  }

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );
  const setLoading = (loading: boolean) => {
    emits("update:loading", loading);
  };

  const { t } = useI18n();
  const workspaceStore = useWorkspacesStore();
  const { updateAccountLogo, workspace } = workspaceStore;
  const { accountName, accountLogo } = storeToRefs(workspaceStore);

  const { errors, findHours, pickupTimesHasChanged, resetState, updatePickupTimes, week } = usePickupIntervals();
  const information = ref<OrganisationInformation>({} as OrganisationInformation);
  const billingAndShippingSame = ref();
  const billingIndex = ref(-1);
  const shippingIndex = ref(-1);

  let initialInformation: OrganisationInformation = {} as OrganisationInformation;
  let initialSame: boolean;

  const dfmAgreementNumber = computed(() => {
    return workspace.account.options["dfm_agreement_number"];
  });

  const fetchInformation = async () => {
    setLoading(true);
    const info = await getOrganisationInfo();
    if (info) {
      information.value = info;
      initialInformation = _.cloneDeep(info);
    }
    getIndices();
    checkIfBillingAndShippingSame(true);
    setLoading(false);
  };

  const updateInformation = () => {
    return homeFetch("workspace/organisation", {
      method: "PUT",
      body: JSON.stringify(information.value),
    }).then(() => {
      fetchInformation();
      workspaceStore.fetchWorkspaces(false);
    });
  };

  const updateCustomerService = () => {
    return homeFetch("workspace/organisation/customer-support", {
      method: "POST",
      body: information.value.customer_support_info,
    });
  };

  const save = () => {
    const savePromises: Promise<any>[] = [];
    if (informationHasChanged.value) {
      savePromises.push(updateInformation());
    }
    if (pickupTimesHasChanged.value) {
      savePromises.push(updatePickupTimes());
    }
    if (customerServiceHasChanged.value) {
      savePromises.push(updateCustomerService());
    }

    Promise.all(savePromises).then(() => {
      $toast.remove("organisation_settings");

      $toast.add({
        title: t("changes_saved"),
        target: "#settings-modal .notification-wrapper",
      });
    });
  };

  const getIndices = () => {
    if (information.value?.contact_info) {
      billingIndex.value = information.value.contact_info.findIndex((c) => c.type === "BILLING");
      shippingIndex.value = information.value.contact_info.findIndex((c) => c.type === "SHIPPING");
    }
  };

  const checkIfBillingAndShippingSame = (setInitial: boolean) => {
    if (information.value?.contact_info) {
      const billing = information.value.contact_info.find((c) => c.type === "BILLING");
      const shipping = information.value.contact_info.find((c) => c.type === "SHIPPING");
      if (billing && shipping) {
        //check all fields except id, created_at, updated_at, latitude, longitude, title and type

        billingAndShippingSame.value = Object.keys(billing).every((key) => {
          if (
            key === "id" ||
            key === "created_at" ||
            key === "updated_at" ||
            key === "latitude" ||
            key === "longitude" ||
            key === "title" ||
            key === "type" ||
            key === "country"
          ) {
            return true;
          }
          return billing[key] === shipping[key];
        });

        if (setInitial) initialSame = billingAndShippingSame.value;
      }
    }
  };

  watch(billingAndShippingSame, (val, oldVal) => {
    if (!information.value.contact_info) return;
    // Check if oldVal is undefined
    if (oldVal === undefined) return;

    // Check if billing and shipping are the same
    if (billingAndShippingSame.value) {
      // Check if billingIndex is -1
      if (billingIndex.value === -1) {
        // Push a new contact info object with the same values as the shipping contact info, but with type "BILLING"
        information.value.contact_info.push({
          ...information.value.contact_info[shippingIndex.value],
          type: "BILLING",
        });
        return;
      }
      // Check if the shipping contact info has changed
      if (
        !_.isEqual(
          information.value.contact_info[shippingIndex.value],
          initialInformation.contact_info[shippingIndex.value]
        )
      ) {
        // Replace the billing contact info with the initial shipping contact info, but with type "BILLING"
        information.value.contact_info[billingIndex.value] = {
          ...initialInformation.contact_info[shippingIndex.value],
          type: "BILLING",
        };
        return;
      } else {
        // Check if the initial billing and shipping are the same
        if (initialSame) {
          // Reset the billing contact info to the initial values
          information.value.contact_info[billingIndex.value] = {
            ...initialInformation.contact_info[billingIndex.value],
          };
        } else {
          // Replace the billing contact info with the shipping contact info, but with type "BILLING"
          information.value.contact_info[billingIndex.value] = {
            ...information.value.contact_info[shippingIndex.value],
            type: "BILLING",
          };
        }
      }
    }
  });

  const informationHasChanged = computed(() => {
    if (!information.value.id) return false;
    return !_.isEqual(information.value, initialInformation);
  });

  const hasChanges = computed(() => {
    return informationHasChanged.value || pickupTimesHasChanged.value;
  });

  watch(
    hasChanges,
    (val, oldVal) => {
      if (!hasChanges.value) {
        $toast.remove("organisation_settings");
        return;
      }
      $toast.add({
        duration: 0,
        id: "organisation_settings",
        title: t("unsaved_changes"),
        target: "#settings-modal .notification-wrapper",
        icon: null,
        actions: [
          {
            text: t("undo"),
            handler: () => {
              resetOrganisationInformation();
              resetState();
            },
          },
          {
            text: t("save_changes"),
            handler: () => {
              save();
            },
          },
        ],
      });
    },
    {
      deep: true,
    }
  );

  const customerServiceHasChanged = computed(() => {
    if (!information.value.customer_support_info) return false;
    return !_.isEqual(information.value.customer_support_info, initialInformation.customer_support_info);
  });

  const resetOrganisationInformation = () => {
    information.value = _.cloneDeep(initialInformation);
    billingAndShippingSame.value = initialSame;
  };

  onMounted(() => {
    fetchInformation().then(() => {
      goToCurrentHeader();
    });
  });

  onDeactivated(() => {
    resetOrganisationInformation();
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
</script>

<script lang="ts">
  export const useOrganisationSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();
    const icon = "regular/nut";
    const title = t("settings.organisation.title");
    const pageTitle = t("settings.organisation.page_title");
    const key = "organisation";
    const headers = [
      t("settings.organisation.workspace.name.title"),
      t("settings.organisation.workspace.address.title"),
      t("pickup_interval"),
      t("weight_unit"),
    ];
    const condition = computed(() => {
      return hasCapability("account.general");
    });

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
      condition,
    };
  };
</script>

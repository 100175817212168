<template>
  <div class="mx-auto flex h-full min-h-0 w-full max-w-[556px] flex-col gap-5 rounded-xl px-2 pb-3">
    <p class="text-title-section text-secondary w-full text-center">{{ t("portal_causes_title") }}</p>
    <div>
      <InputText
        v-model="causesString"
        :size="ComponentSize.lg"
        :placeholder="t('create_more_with_comma')"
        @keypress.enter.stop.prevent="addCauses(causesString)"
      >
        <template #suffix>
          <Button :disabled="causesString == ''" type="button" @click="addCauses(causesString)">{{
            t("create_cause")
          }}</Button>
        </template>
      </InputText>
      <Callout class="mt-1" type="ghost" icon="WarningDiamond">
        {{ t("portal_causes_desc") }}
      </Callout>
    </div>
    <div>
      <p class="text-body-default text-quarterary mb-1">{{ t("recommended_causes") }}</p>
      <div class="flex flex-wrap gap-1.5">
        <Badge v-for="cause in filteredCauses" :key="cause" class="cursor-pointer" @click="addCauses(cause)">
          <p>{{ cause }}</p>
        </Badge>
      </div>
    </div>
    <div class="overflow-auto">
      <p class="text-body-default text-quarterary mb-1">{{ t("selected_causes") }}</p>
      <VueDraggableNext class="overflow-auto" :list="causes" ghost-class="sortable-ghost">
        <div v-for="(cause, index) in causes" :key="index">
          <Divider class="mb-0.5" :class="index !== 0 ? 'mt-0.5' : ''" :spacing="false" />
          <div class="flex cursor-grab items-center gap-3 px-3 py-1.5">
            <Icon src="bold/dots_nine" />
            <span>{{ cause.text }}</span>
            <Button
              class="ml-auto"
              type="button"
              :variant="ButtonVariant.Tertiary"
              :size="ComponentSize.sm"
              icon-only
              @click="removeCause(index)"
            >
              <Icon class="text-foreground-secondary" src="filled/Close" />
            </Button>
          </div>
        </div>
      </VueDraggableNext>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { VueDraggableNext } from "vue-draggable-next";

  const { t } = useI18n();
  defineProps<{
    schema: any;
    values: any;
  }>();
  const maxId = ref(1);
  const causesString = ref("");
  const recomendedCauses = ref([
    t("recommended_return_causes.to_large"),
    t("recommended_return_causes.to_small"),
    t("recommended_return_causes.regret_purchase"),
    t("recommended_return_causes.wrong_order"),
    t("recommended_return_causes.wrong_item"),
  ]);
  const filteredCauses = computed(() => {
    return recomendedCauses.value.filter((cause) => {
      return !causes.value?.some((c) => c.text == cause);
    });
  });

  const addCauses = (cause: string) => {
    if (!causes.value) {
      causes.value = [];
    }
    if (cause == "") {
      return;
    }
    if (cause.includes(",")) {
      cause.split(",").forEach((c) => {
        const id = ++maxId.value;
        causes.value.push({ id: id, text: c.trim() });
      });
      causesString.value = "";
      return;
    }
    const id = ++maxId.value;
    causes.value.push({ id: id, text: cause });
    causesString.value = "";
  };
  const removeCause = (index: number) => {
    causes.value.splice(index, 1);
  };

  const { value: causes } = useField(() => "settings.causes", undefined, {
    syncVModel: true,
  });
</script>

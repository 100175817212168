export default defineNuxtRouteMiddleware(async (to) => {
  // skip middleware on server
  if (import.meta.server) return;
  if (isDomainDev()) return;

  const { getAuthCookie, getAuthCookieExpiresIn } = useAuth();
  const { accountIsBusiness } = storeToRefs(useWorkspacesStore());
  const { domain, currentSubdomain, isDfmDomain } = decideDomainInfo();
  // skip middleware on layout auth
  if (to.meta.layout == "auth") {
    return handleDomainRedirects(currentSubdomain, domain, isDfmDomain, to);
  }

  if (!accountIsBusiness.value) {
    const token = getAuthCookie().value;
    const expires_in = getAuthCookieExpiresIn();
    return navigateTo(`https://account.coolrunner.dk/login/o?token=${token}&expires_in=${expires_in * 1000}`, {
      external: true,
    });
  }

  return handleDomainRedirects(currentSubdomain, domain, isDfmDomain, to);
});

const handleDomainRedirects = (currentSubdomain: string, domain: string, isDfmDomain: boolean, to) => {
  if (currentSubdomain !== "platform" && !isDfmDomain) {
    return navigateTo(
      { path: `https://platform.${domain}${to.fullPath}` },
      {
        external: true,
      }
    );
  } else if (isDfmDomain && currentSubdomain) {
    return navigateTo(
      { path: `https://${domain}${to.fullPath}` },
      {
        external: true,
      }
    );
  }

  return;
};

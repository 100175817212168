import { inject, ref } from "vue";
import type { INewShipmentService } from "./types";
import type { IShipment } from "~/types/shipment";

export const open = ref(false);
export const noTransferOpen = ref(false);
export const wizard = ref(null);
export const loading = ref(false);
export const mode = ref<"new" | "return" | "duplicate">("new");
export const return_shipment = ref<any>(null);

export const getPhonePrefixFromObject = (object: any) => {
  let prefix = object?.phone_prefix;
  if (!prefix) {
    return "";
  }

  if (!prefix.startsWith("+")) {
    prefix = "+" + prefix;
  }

  return prefix;
};

declare global {
  const $newshipment: INewShipmentService;
}

/**
 * A unique identifier to access the provided/injected method
 */
export const NewShipmentSymbol = Symbol();

/**
 * Composable which allows accessing the NewShipment service in Composition API
 * @returns NewShipment
 */
export function useNewShipment() {
  const NewShipment = inject(NewShipmentSymbol);
  if (!NewShipment) {
    throw new Error("No NewShipment provided!");
  }

  return NewShipment;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const NewShipmentService = {
  install: (app) => {
    const { isDFM } = storeToRefs(useWorkspacesStore());

    const NewShipmentService: INewShipmentService = {
      toggle: () => {
        EventBus.$emit("NEWSHIPMENT_TOGGLE");
      },
      open: () => {
        if (isDFM.value) {
          EventBus.$emit("NEWSHIPMENT_OPEN");
        } else {
          $loader.toggle();
          homeFetch("customers/active-transfer").then((res) => {
            $loader.toggle();
            if (res.data) {
              EventBus.$emit("NEWSHIPMENT_OPEN");
            } else {
              EventBus.$emit("NEWSHIPMENT_NO_TRANSFER");
            }
          });
        }
      },
      close: () => {
        EventBus.$emit("NEWSHIPMENT_CLOSE");
      },
      duplicate: (shipment: IShipment) => {
        EventBus.$emit("NEWSHIPMENT_DUPLICATE", shipment);
      },
      return: (shipment?: IShipment) => {
        EventBus.$emit("NEWSHIPMENT_RETURN", shipment);
      },
    };
    app.config.globalProperties.$NewShipment = NewShipmentService;
    if (typeof window !== "undefined") window.$newshipment = NewShipmentService;
    app.provide(NewShipmentSymbol, NewShipmentService);
  },
};

<template>
  <button
    ref="resultEl"
    :class="['flex w-full items-center gap-2.5 rounded-lg px-2.5 py-1', { 'bg-00': isActive }]"
    :data-result="`${props.result.package_number}_${props.result.id}`"
    @click="onClickWrapper(onClick, $event)"
  >
    <div class="flex w-full items-center gap-2.5">
      <Avatar
        size="xs"
        :entity="{
          name: props.result.carrier,
          image: getCarrierLogoUrl(props.result.carrier),
        }"
      />
      <div class="flex flex-col items-start">
        <div class="text-body-lg text-secondary flex items-center font-medium">
          <p class="max-w-xs truncate">
            <span v-for="(part, i) in title" :key="part" :class="{ 'bg-warning-02': i === 1 }">{{ part }}</span>
          </p>
          <span class="ms-2">–</span>
          <span class="text-body-default text-secondary ms-2 text-nowrap">{{ matchedAttibute }}</span>
        </div>
        <div class="text-body-sm w-fit">
          <span class="text-quarterary">{{ type }} / </span>
          <span
            v-for="(part, i) in highlightSearch(props.result.package_number, gsStore.search)"
            :key="part"
            :class="i === 1 ? 'bg-warning-02' : 'text-secondary'"
            >{{ part }}</span
          >
        </div>
      </div>
      <div :class="['text-quarterary ms-auto items-center gap-1 p-2', isActive ? 'flex' : 'hidden']">
        <span class="text-body-sm font-medium">{{ t("select") }}</span>
        <Icon src="ArrowUDownLeft" />
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
  import type { SearchResultCategory, SearchResultItem } from "~/types/search";

  defineOptions({
    name: "GlobalSearchResult",
  });

  const props = withDefaults(
    defineProps<{
      category?: SearchResultCategory;
      icon?: string | false;
      isPrevious?: boolean;
      result: SearchResultItem;
    }>(),
    {
      icon: "ClockCounterClockwise",
      category: undefined,
    }
  );
  const emit = defineEmits(["close"]);

  const { t } = useI18n();
  const gsStore = useGlobalSearchStore();
  const router = useRouter();

  const resultEl = useTemplateRef("resultEl");

  const title = computed(() => {
    return highlightSearch(highlightAttributeStringValue(props.result), gsStore.search);
  });

  const type = computed(() => {
    return globalSearchResultType(props.result.type);
  });

  const matchedAttibute = computed(() => {
    return globalSearchResultTitleAttribute(props.result);
  });

  const isActive = computed(() => {
    return gsStore.currentResult?.id === props.result.id;
  });

  const onClick = computed(() => {
    return globalSearchResultOnClick(props.result);
  });

  watch(isActive, (isActive) => {
    if (isActive && gsStore.usingKeyboard) {
      resultEl.value?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  });

  const onClickWrapper = (oc: Record<string, any> | ((...args: any[]) => any), e: MouseEvent): void => {
    addResultToRecentSearches(props.result);
    emit("close");

    if (typeof oc === "function") {
      return oc();
    } else {
      const resolved = router.resolve(oc);
      if (e.metaKey || e.ctrlKey) {
        return window.open(resolved.href, "_blank");
      }

      return useRouter().push(oc);
    }
  };
</script>

<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <!--<Callout type="aware" icon="filled/WarningDiamond" >
            {{ t('settings.return_portal.not_yet_implemented') }}
    </Callout>-->

    <SettingsSection
      :title="t('settings.return_portal.label.title')"
      :description="t('settings.return_portal.label.description')"
    >
      <template #headerButtons>
        <Button class="mt-auto" :variant="ButtonVariant.Primary" @click="createOpen = true">{{
          t("create_return_portal")
        }}</Button>
      </template>
    </SettingsSection>
    <Divider />

    <EmptyView
      v-show="hasNoReturnPortals"
      :title="t('settings.return_portal.empty.title')"
      :description="t('settings.return_portal.empty.description')"
    >
      <template #illustration>
        <img
          src="~/assets/illustrations/emptyViews/ec-safe-browser-shopping.svg"
          alt="No return portals illustration"
        />
      </template>
    </EmptyView>

    <div v-if="!hasNoReturnPortals" class="flex flex-col gap-6">
      <template v-for="(portal, idx) in portals" :key="portal.uid">
        <ListItem
          :portal="portal"
          :checkouts="checkouts"
          @update="fetchReturnPortals()"
          @edit-portal="(val) => openEdit(val)"
        />
        <Divider v-if="idx !== portals.length - 1" />
      </template>
    </div>
    <SetupModal
      :portal="selectedPortal"
      :information="information"
      :open="createOpen"
      @update="fetchReturnPortals()"
      @close="[(createOpen = false), (selectedPortal = null)]"
    />
  </div>
</template>

<script setup lang="ts">
  import type { ISettingsFlow } from "@/types/settings";
  import type { IReturnPortal } from "~/types/returns";

  import ListItem from "../components/ReturnPortal/ListItem.vue";
  import SetupModal from "../components/ReturnPortal/SetupModal.vue";

  const { t } = useI18n();
  const loading = ref<boolean>(false);

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );

  const createOpen = ref(false);
  const checkouts = ref<{ label: string; value: string; icon: string }[]>([]);

  const selectedPortal = ref<object | null>(null);

  const information = ref<OrganisationInformation>({} as OrganisationInformation);

  const portals = ref<IReturnPortal[]>([]);

  const openEdit = (portal: object) => {
    selectedPortal.value = portal;
    createOpen.value = true;
  };

  const hasNoReturnPortals = computed(() => portals.value.length === 0 && !loading.value);

  const fetchReturnPortals = () => {
    setLoading(true);
    return homeFetch("workspace/return-portals")
      .then((response) => {
        if (response?.data) {
          portals.value = response.data as IReturnPortal[];
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  onMounted(() => {
    fetchReturnPortals().then(() => {
      goToCurrentHeader();
      fetchInformation();
    });
    getCheckouts();
  });

  const fetchInformation = async () => {
    const info = await getOrganisationInfo();
    information.value = info;
  };

  const { checkoutsForSelect } = useCheckouts();

  const getCheckouts = async () => {
    const fetchedCheckouts = await checkoutsForSelect();
    checkouts.value = fetchedCheckouts.checkouts.map((checkout: IShopCheckout) => {
      return {
        label: checkout.name,
        value: checkout.id,
        icon: "bold/CreditCard",
      };
    });
  };
  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => {
    loading.value = l;
    emits("update:loading", l);
  };
</script>

<script lang="ts">
  export const useReturnPortalSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();

    const icon = "regular/ArrowCounterClockwise";
    const title = t("settings.return_portal.title");
    const pageTitle = t("settings.return_portal.page_title");
    const key = "returnPortals";
    const condition = computed(
      () =>
        !useWorkspacesStore().isDFM &&
        (hasCapability("account.returnportal") || hasCapability("account.returnportal.edit"))
    );
    const group = "setup";

    return {
      icon,
      pageTitle,
      title,
      key,
      condition,
      group,
    };
  };
</script>

<template>
  <Modal ref="modal" v-model:open="open" class="shadow-lvl-05" max-width="1020px">
    <div class="flex max-w-full flex-col gap-5 p-[14px]">
      <ListSelector
        v-model="transferUnitType"
        class="[&_.list-select-option]:flex [&_.list-select-option]:flex-col [&>div]:flex-row"
        :values="unitTypes"
        value-key="value"
      >
        <template #title="{ value }">
          <div class="flex flex-1 items-center justify-between gap-4">
            <div class="text-body-lg-heavy">
              {{ value.label }}
            </div>
            <Badge class="text-body-default" :variant="ButtonVariant.Primary">
              {{ value.badge }}
            </Badge>
          </div>
        </template>

        <template #value="{ value }">
          <div class="flex h-full w-full flex-col gap-3">
            <div class="text-body-default text-tertiary">{{ value.description }}</div>

            <div class="flex flex-col gap-1.5">
              <template v-for="(tableEntry, idx) in value.table" :key="idx">
                <div class="text-body-default text-secondary flex justify-between gap-2">
                  <div>{{ tableEntry.left }}</div>
                  <div class="flex items-center gap-1 text-right">
                    {{ tableEntry.right }}
                  </div>
                </div>
                <Button
                  v-if="tableEntry.infoLink"
                  class="mr-1 !justify-start"
                  :variant="ButtonVariant.PrimaryLink"
                  :href="tableEntry.infoLink.link"
                  target="_blank"
                  @click.stop=""
                >
                  {{ tableEntry.infoLink.title }} <Icon src="ArrowUpRight" />
                </Button>
                <Divider v-if="idx !== value.table.length - 1" />
              </template>
            </div>
            <template v-if="value.prices">
              <Divider />
              <div class="flex flex-col gap-1.5">
                <div class="text-body-default-heavy">{{ t("price_excl_vat_and_fuel") }}:</div>
                <div class="flex flex-col gap-1.5">
                  <template v-for="(price, idx) in value.prices" :key="idx">
                    <div class="text-body-default text-tertiary flex justify-between gap-2">
                      <div>{{ price.left }}</div>
                      <div class="flex items-center gap-1 text-right">
                        {{ price.right }}
                      </div>
                    </div>
                  </template>
                  <Button
                    v-if="value.link"
                    class="mr-1 !justify-start"
                    :variant="ButtonVariant.PrimaryLink"
                    :href="value.link"
                    @click.stop=""
                  >
                    {{ t("settings.plan.pickup.default_unit_type.bag.auto_delivery") }}
                    <Icon src="ArrowUpRight" />
                  </Button>
                </div>
              </div>
            </template>
          </div>
        </template>
      </ListSelector>
      <Callout type="aware" icon="WarningDiamond">
        {{ t("settings.plan.pickup.default_unit_type.warning") }}
      </Callout>
    </div>

    <ModalFooter class="border-t-0">
      <Button type="button" @click="() => emit('update:open', false)">
        {{ t("cancel") }}
      </Button>
      <Button
        :variant="ButtonVariant.Primary"
        :disabled="!transferUnitTypeHasChanged"
        :loading="updating"
        type="button"
        @click="updateUnitType"
      >
        {{ t("save_changes") }}
      </Button>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
  const modal = ref<HTMLDivElement | null | undefined>();
  const open = defineModel<boolean>("open", { default: false });
  const { t } = useI18n();
  const emit = defineEmits(["update:open"]);
  const accountsStore = useWorkspacesStore();
  const { accountOptions } = storeToRefs(accountsStore);
  const { fetchWorkspaces } = accountsStore;

  const updating = ref(false);

  const unitTypes = ref([
    {
      value: "BAG",
      label: t("bag", 99),
      badge: t("settings.plan.pickup.default_unit_type.bag.badge"),
      description: t("settings.plan.pickup.default_unit_type.bag.description"),
      table: [
        { left: t("dimensions"), right: "130 x 85 cm" },
        { left: t("weight"), right: "Max 30 kg" },
        {
          left: t("load_capacity"),
          right: `${t("up_to")} 120 L`,
          infoLink: {
            title: t("load_capacity_read_more", { type: t("bag", 99).toLowerCase() }),
            link: "https://help.homerunner.com",
          },
        },
      ],
      link: {
        title: t("settings.plan.pickup.default_unit_type.bag.auto_delivery"),
        link: "https://help.homerunner.com",
      },
    },
    {
      value: "PALLET",
      label: t("pallet", 99),
      badge: t("settings.plan.pickup.default_unit_type.pallet.badge"),
      description: t("settings.plan.pickup.default_unit_type.pallet.description"),
      table: [
        { left: t("dimensions"), right: "130 x 85 cm" },
        { left: t("weight"), right: "Max 30 kg" },
        {
          left: t("load_capacity"),
          right: `${t("up_to")} 120 L`,
          infoLink: {
            title: t("load_capacity_read_more", { type: t("pallet", 99).toLowerCase() }),
            link: "https://help.homerunner.com",
          },
        },
      ],
    },
  ]);

  const transferUnitType = ref("");

  watch(
    () => open.value,
    () => {
      if (open.value) {
        transferUnitType.value = unref(accountOptions.value?.transfer?.transfer_pickup_unit_type);
      } else {
        transferUnitType.value = "";
      }
    }
  );

  const transferUnitTypeHasChanged = computed(() => {
    return transferUnitType.value !== unref(accountOptions.value?.transfer?.transfer_pickup_unit_type);
  });

  const updateUnitType = () => {
    updating.value = true;
    return homeFetch("workspace/plan/unit-type", {
      method: "PUT",
      body: {
        transfer_pickup_unit_type: transferUnitType.value,
      },
    })
      .then(() => {
        fetchWorkspaces(false);
        emit("update:open", false);
      })
      .finally(() => {
        updating.value = false;
      });
  };
</script>

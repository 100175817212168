<template>
  <SlideIn
    ref="modal"
    :submit-ready="true"
    :show-footer="step == 2"
    :button-text="t('create_ticket')"
    :title="t('create_ticket')"
    :open="open"
    @close="close()"
    @submit="() => createTicket()"
  >
    <div class="grow overflow-auto">
      <div v-if="ticketId" class="flex h-full flex-col justify-center">
        <div class="mx-auto my-auto flex max-w-max flex-col items-center justify-center">
          <p class="text-body-lg-heavy mb-5">{{ t("ticket_already_created") }}</p>
          <Button
            class="max-auto max-w-max text-center"
            :variant="ButtonVariant.Primary"
            @click="goToTicket(ticketId)"
            >{{ t("go_to_entity", { entity: t("ticket", 1).toLowerCase() }) }}</Button
          >
        </div>
      </div>
      <div v-if="step === 0" class="my-auto flex h-full flex-col items-center justify-center">
        <img src="/assets/illustrations/oc-connecting.svg" alt="" class="w-5/6 max-w-80" />
        <div class="mt-10 flex flex-col gap-4">
          <p class="text-title-body text-secondary">{{ t("new_ticket_which_shipment") }}</p>
          <InputText
            v-model="newTicketPackageNumber"
            :placeholder="t('set_package_number')"
            @keyup.enter.stop="() => createNewTicket(newTicketPackageNumber)"
          />
          <Button
            :variant="ButtonVariant.Primary"
            :disabled="!newTicketPackageNumber"
            class="w-full"
            @click="() => createNewTicket(newTicketPackageNumber)"
            >{{ t("new_ticket") }}</Button
          >
        </div>
      </div>
      <div v-if="types && types.length !== 0 && step == 1" id="types" class="w-full px-5 pt-2 pb-5">
        <div
          v-for="ticketType in types"
          :key="ticketType.id"
          class="border-00 shadow-lvl-01 shadow-surface-lvl-01 mb-3 flex cursor-pointer gap-4 rounded-lg border p-4"
          @click="selectType(ticketType)"
        >
          <div>
            <p class="text-body-default-heavy text-secondary mb-0.5">{{ ticketType.name }}</p>
            <p class="text-body-default text-quarterary">{{ ticketType.description }}</p>
          </div>
          <div class="ml-auto flex items-center justify-center">
            <Icon src="arrow_right" />
          </div>
        </div>
        <div class="h-14" />
      </div>

      <div v-if="step == 2" class="w-full px-5 pt-2 pb-5">
        <div class="bg-surface-lvl-01 mb-10 flex rounded-lg px-3 py-2">
          <p class="text-body-default-heavy text-secondary">{{ selectedObject.name }}</p>
          <p class="text-body-default-heavy text-brand ml-auto cursor-pointer" @click="step = 1">{{ t("change") }}</p>
        </div>
        <div class="flex flex-col gap-10">
          <div v-for="(attribute, index) in selectedObject.attributes" :key="attribute.id">
            <div class="relative flex gap-2">
              <p class="text-attribute text-body-sm-heavy">{{ attribute.name }}</p>
              <div class="!max-w-[200px]">
                <Tooltip class="overflow-hidden" :text="attribute.description" />
              </div>
              <Button
                v-if="attribute?.options?.link"
                :variant="ButtonVariant.PrimaryLink"
                :size="ComponentSize.sm"
                :href="attribute?.options?.link"
                target="_blank"
                >{{ t("download_template") }}</Button
              >
            </div>
            <TypeInput
              :index="index"
              :shipment-id="shipmentId"
              :attribute="attribute"
              :type="attribute.type"
              @emit-value="(val) => addAttributeValue(val, attribute)"
            />
          </div>
        </div>
        <InputParagraph
          class="mt-10"
          :error="messageError"
          :label="t('ticket_description')"
          @update:model-value="(val) => setMessage(val)"
        />
      </div>
      <Overlay v-if="loading" class="absolute inset-x-0 top-0 left-0 h-lvh min-h-[300px] w-full" />
    </div>
  </SlideIn>
</template>

<script setup lang="ts">
  import type { ITicketExistsResponse, ITicketTypesResponse, TicketType } from "../types";

  import { open } from "../service";
  import TypeInput from "./typeInput.vue";

  defineOptions({
    name: "CreateTicket",
  });

  const { t } = useI18n();
  const loading = ref(false);
  const types = ref<TicketType[]>([]);
  const packageNumber = ref("");
  const shipmentId = ref("");
  const selectedType = ref("");
  const step = ref(0);
  const ticketId = ref<number>();
  const messageError = ref("");
  const newTicketPackageNumber = ref("");

  const ticket = ref({
    shipment_id: "",
    package_number: "",
    type: "",
    from_name: "",
    from_email: "",
    cc: "",
    message: "",
    attributes: [],
  });

  const selectedObject = computed(() => {
    if (!selectedType.value) {
      return null;
    }
    return types.value.find((type) => type.key === selectedType.value);
  });

  const { submitTicket, createNewTicket } = useTickets();

  const selectType = (type: object) => {
    selectedType.value = type.key;
    ticket.value.type = type.state;

    step.value = 2;
  };

  const setMessage = (val: any) => {
    messageError.value = "";
    ticket.value.message = val;
  };

  const { userName } = storeToRefs(useWorkspacesStore());

  onMounted(() => {
    EventBus.$on("NEWTICKET_TOGGLE", () => {
      open.value = !open.value;
    });
    EventBus.$on("NEWTICKET_ADD", (data: ITicketTypesResponse | ITicketExistsResponse, packageNum: string) => {
      loading.value = true;
      step.value = 1;
      selectedType.value = "";
      packageNumber.value = packageNum;
      if ((data as ITicketExistsResponse).ticket_id) {
        ticketId.value = (data as ITicketExistsResponse).ticket_id;
        types.value = [];
        loading.value = false;

        return;
      }

      types.value = (data as ITicketTypesResponse).data;
      ticket.value = {
        shipment_id: (data as ITicketTypesResponse).shipment_id.toString(),
        package_number: packageNum,
        type: "",
        from_name: userName.value,
        from_email: "",
        cc: "",
        message: "",
        attributes: [],
      };
      shipmentId.value = (data as ITicketTypesResponse).shipment_id.toString();

      loading.value = false;
    });
    EventBus.$on("NEWTICKET_OPEN", () => {
      open.value = true;
    });
    EventBus.$on("NEWTICKET_CLOSE", () => {
      open.value = false;
      ticketId.value = undefined;
      types.value = [];
      step.value = 0;
      newTicketPackageNumber.value = "";
    });
  });

  onUnmounted(() => {
    EventBus.$off("NEWTICKET_TOGGLE");
    EventBus.$off("NEWTICKET_OPEN");
    EventBus.$off("NEWTICKET_CLOSE");
  });

  const addAttributeValue = (val: any, attribute: Record<string, any>) => {
    attribute.error = null;
    const attributeValues = {
      key: attribute.key,
      type: attribute.type,
      type_attribute_id: attribute.id,
      value: val,
    };
    const existingAttributeIndex = ticket.value.attributes.findIndex((attr) => attr.key === attribute.key);
    if (existingAttributeIndex !== -1) {
      ticket.value.attributes.splice(existingAttributeIndex, 1);
    }

    if (!val && attribute.optional) {
      return;
    }

    if (val.length === 0) {
      attributeValues.value = null;
      ticket.value.attributes.splice(existingAttributeIndex, 1);
      return;
    } else {
      if (attribute.type === "file" || attribute.type === "dor" || attribute.type === "file_with_pdf") {
        attributeValues.fileName = val[0].name;
        attributeValues.value = val[0].value;
      } else if (attribute.type === "file[]") {
        attributeValues.fileNames = val.map((v: any) => v.name);
        attributeValues.value = val.map((v: any) => v.value);
      }
    }
    ticket.value.attributes.push(attributeValues);
  };

  const createTicket = () => {
    const allFilled = selectedObject.value.attributes.every((attribute) => {
      if (attribute.optional) {
        return true;
      }
      return (
        ticket.value.attributes.some((attr) => attr.type_attribute_id === attribute.id && attr.value) &&
        ticket.value.message
      );
    });
    if (!allFilled) {
      //create array of missing attributes
      const missingAttributes = selectedObject.value.attributes.filter((attribute) => {
        return !ticket.value.attributes.some((attr) => attr.type_attribute_id === attribute.id && attr.value);
      });
      missingAttributes.forEach((element) => {
        const originalAttribute = selectedObject.value.attributes.find((attr) => attr.id === element.id);
        originalAttribute.error = t("required");
      });

      if (!ticket.value.message) {
        messageError.value = t("required");
      }
      return;
    }
    loading.value = true;
    submitTicket(ticket.value)
      .then((response) => {
        if (response.ticketId) {
          EventBus.$emit("NEWTICKET_CREATED");
          EventBus.$emit("NEWTICKET_CLOSE");
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const close = () => {
    EventBus.$emit("NEWTICKET_CLOSE");
  };

  const goToTicket = (ticketId: number) => {
    EventBus.$emit("NEWTICKET_CLOSE");
    const link = useRouter().resolve({ name: "tickets-id", params: { id: ticketId } })?.href;
    useRouter().push(link);
  };
</script>

<template>
  <div class="user-row border-00 flex flex-col justify-between border-b @sm:flex-row @sm:gap-4">
    <div class="flex items-center gap-2.5 self-stretch px-2 py-1.5">
      <Avatar :entity="user" size="xs" random-color />
      <div
        class="text-body-default-heavy mr-auto flex w-full shrink-0 flex-col overflow-hidden"
        :class="{
          'justify-center': !isVerified,
        }"
      >
        <template v-if="isVerified">
          <span>{{ user.name }}</span>
          <span class="text-body-sm text-quarterary text-nowrap text-ellipsis">{{ user.email }}</span>
        </template>
        <template v-else>
          <span>{{ user.email }}</span>
        </template>
      </div>
    </div>
    <div class="flex w-full items-center gap-4 @sm:w-auto">
      <BadgeChip v-if="!isVerified" class="text-nowrap" type="default">
        {{ t("settings.users.awaiting_verification") }}
      </BadgeChip>
      <div v-if="hasCapability('account.users.edit')" class="w-full @sm:max-w-[200px]">
        <Button
          ref="trigger"
          class="w-full !justify-between"
          :variant="ButtonVariant.Tertiary"
          :active="showDropdown"
          :size="ComponentSize.sm"
          @click="toggleDropdown"
        >
          {{ userRoleLabel }}
          <template #suffix>
            <Icon src="CaretDown" :size="ComponentSize.sm" />
          </template>
        </Button>
      </div>
    </div>
  </div>

  <Dropdown
    ref="dropdown"
    v-model:open="showDropdown"
    max-width="162px"
    :toggle-element="trigger"
    :calculate-position="calculatePosition"
    item-max-width="420px"
  >
    <div class="dropdown__content flex flex-col p-1.5">
      <DropdownItem
        v-for="role in roles"
        :key="role.value"
        :disabled="isCurrentRole(role.value) || loading"
        @click="() => changeRole(role.value)"
      >
        <div class="max-w-105 flex-col gap-2">
          <div class="overflow-hidden text-ellipsis">{{ role.label }}</div>
          <div v-if="role.subtext" class="text-body-sm text-quarterary text-wrap">{{ role.subtext }}</div>
        </div>
      </DropdownItem>
      <Divider class="mx-1" />
      <DropdownItem variant="destructive" :disabled="loading || user.id == userId" @click="removeUser">
        {{ t("settings.users.remove_from_organisation") }}
      </DropdownItem>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      user: Record<string, any>;
      roles: { value: string; label: string; subtext: string }[];
    }>(),
    {}
  );
  const loading = ref(false);
  const { userId, fetchWorkspaces } = useWorkspacesStore();
  const { hasCapability } = useCapabilities();

  const isCurrentRole = (role: string) => {
    return props.user.roles && props.user.roles.length > 0 && props.user.roles[0].id == role;
  };

  const userRoleLabel = computed(() => {
    const role = t("settings.users.roles.no_role");

    if (!props.user.roles || props.user.roles.length === 0) {
      return role;
    }

    return (
      props.roles.find((role) => role.value == props.user.roles[0].id)?.label || t("settings.users.roles.unkown_role")
    );
  });

  const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition } = useDropdown();

  const emits = defineEmits(["fetch"]);

  const removeUser = () => {
    $confirm({
      destructive: true,
      message: t("settings.users.confirm_remove_user", {
        user: props.user.name || props.user.email,
      }),
      onConfirm: () => {
        loading.value = true;

        homeFetch(`workspace/users/${props.user.id}`, {
          method: "DELETE",
        })
          .then(() => {
            $toast.add({
              title: t("settings.users.remove_user.success", {
                user: props.user.name || props.user.email,
              }),
              icon: "users",
            });
            emits("fetch");
          })
          .finally(() => {
            toggleDropdown();
            loading.value = false;
          });
      },
    });
  };

  const changeRole = (role: string) => {
    if (role == props.user.roles[0].id) {
      return;
    }
    $confirm({
      message: t("settings.users.confirm_change_role", {
        user: props.user.name || props.user.email,
        newRole: props.roles.find((r) => r.value == role)?.label,
        oldRole: props.roles.find((r) => r.value == props.user.roles[0].id)?.label,
      }),
      onConfirm: () => {
        loading.value = true;

        homeFetch(`workspace/users/${props.user.id}`, {
          method: "PUT",
          body: {
            name: props.user.name,
            email: props.user.email,
            roles: [role],
          },
        })
          .then(() => {
            $toast.add({
              title: t("settings.users.change_role.success", {
                user: props.user.name || props.user.email,
                role: props.roles.find((r) => r.value == role)?.label,
              }),
              icon: "users",
            });
            emits("fetch");
            if (props.user.id == userId) {
              return fetchWorkspaces(false);
            }
          })
          .finally(() => {
            toggleDropdown();
            loading.value = false;
          });
      },
    });
  };

  const isVerified = computed(() => {
    return props.user.account_users && props.user.account_users.length > 0 && props.user.account_users[0].verified;
  });
</script>

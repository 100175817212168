import { Day } from "@/types/services";
import _ from "lodash";
import { DateTime } from "luxon";
import type { Interval, PickupIntervals } from "@/types/services";

export const usePickupIntervals = () => {
  // Data
  const servicesStore = useServicesStore();
  const { services } = storeToRefs(servicesStore);
  const { fetchServices } = servicesStore;

  const week = [Day.MONDAY, Day.TUESDAY, Day.WEDNESDAY, Day.THURSDAY, Day.FRIDAY, Day.SATURDAY, Day.SUNDAY];
  const newPickupTimes = ref<PickupIntervals>();
  const errors = reactive({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  // Computed
  const initialPickupIntervals = computed<PickupIntervals | undefined>(() => {
    const pickupIntervals = _.cloneDeep(services.value.pickup_intervals);
    for (const day of week) {
      if (!pickupIntervals || !pickupIntervals[day]) return;
      cleanDay(pickupIntervals[day]);
    }
    return pickupIntervals;
  });

  const pickupTimesHasChanged = computed(() => {
    return !_.isEqual(initialPickupIntervals.value, newPickupTimes.value);
  });

  // Fuctions
  const cleanDay = (day: Interval) => {
    if (!day.warehouse_closing) {
      day.warehouse_closing = "00:00";
    }
  };

  const resetPickupTimes = () => {
    newPickupTimes.value = _.cloneDeep(initialPickupIntervals.value);
  };

  const findHours = (day: Day): Interval | undefined => {
    if (!newPickupTimes.value) return;
    return newPickupTimes.value[day];
  };

  const setErrors = () => {
    if (!newPickupTimes.value) return;
    for (const day of week) {
      if (
        newPickupTimes.value[day].latest_booking &&
        DateTime.fromISO(newPickupTimes.value[day].warehouse_closing!) <
          DateTime.fromISO(newPickupTimes.value[day].latest_booking!)
      ) {
        errors[day] = true;
      }
    }
  };

  const resetErrors = () => {
    for (const day of week) {
      errors[day] = false;
    }
  };

  const resetState = () => {
    resetPickupTimes();
    resetErrors();
  };

  const updatePickupTimes = () => {
    return homeFetch("workspace/organisation/pickup-times", {
      method: "POST",
      body: { pickup_intervals: newPickupTimes.value },
    })
      .then(fetchServices)
      .catch(() => {
        setErrors();
        throw new Error("Error updating pickup times");
      });
  };

  // Lifecycle
  onMounted(() => {
    resetState();
  });

  return {
    errors,
    findHours,
    initialPickupIntervals,
    newPickupTimes,
    pickupTimesHasChanged,
    resetState,
    updatePickupTimes,
    week,
  };
};

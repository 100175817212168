import type { ITableRowAction } from "~/components/Table/types";

export const getIconForAction = (
  action: ITableRowAction | null,
  test: test,
  useDefault?: boolean
): IconSource | CustomIconSource | "undefined" | null => {
  if (!action) return null;
  if (action.icon) {
    const isCustom = action.icon.startsWith("custom/");
    let icon: string = action.icon;
    if (isCustom) icon = icon.replace("custom/", "");

    if (!icon.includes("/")) icon = `bold/${icon}`;

    if (isCustom) return `custom/${icon}` as CustomIconSource;
    else return icon as IconSource;
  }

  switch (action.key) {
    case "print":
      return "bold/printer";
    case "download":
      return "bold/file_arrow_down";
    case "ticket":
      return "bold/tag_simple";
    case "focus":
      return "bold/binoculars";
    case "unfocus":
      return "fill/binoculars";
    case "complaint":
      return "bold/arrow_counter_clockwise";

    default:
      return useDefault ? "undefined" : null;
  }
};

export const getActionTextVariant = (action: ITableRowAction) => {
  if (action.destructive) return "destructive";

  switch (action.key) {
    case "delete":
    case "cancel":
      return "destructive";
    default:
      return "default";
  }
};

export const isActionVisible = (action?: ITableRowAction | null, item?: any) => {
  if (!action) return true;
  if (!action.hidden) return true;
  //if action.hidden is a function, call it with the item
  if (typeof action.hidden === "function" && item) return !action.hidden(item);
  return !action.hidden;
};

export const cleanActionsList = (actions: (ITableRowAction | null)[] = [], item?: any) => {
  if (item) actions = actions.filter((action) => isActionVisible(action, item));

  // Remove null elements from the start and end of the array
  while (actions.length > 0 && actions[0] == null) actions.shift();
  while (actions.length > 0 && actions[actions.length - 1] == null) actions.pop();

  // Ensure only one null element between actions
  let i = 0;
  while (i < actions.length) {
    if (actions[i] == null && actions[i + 1] == null) actions.splice(i, 1);
    else i++;
  }

  return actions;
};

export const resolveActionLink = (action: ITableRowAction, item: any) => {
  if (action.link) {
    if (typeof action.link === "function") return action.link(item);
    return action.link;
  }

  return null;
};

<template>
  <div ref="step" class="mx-auto h-[67vh] w-full max-w-[650px] overflow-y-auto px-6 pb-5 text-center">
    <div class="border-01 mb-5 flex h-9 items-center gap-2 rounded-full border px-4">
      <p class="text-body-default-heavy text-quarterary">{{ t(reverseMode) }}</p>
      <p class="text-body-default-heavy text-secondary truncate">
        {{ values[reverseMode]?.name
        }}<span v-if="values[reverseMode]?.attention">, att. {{ values[reverseMode]?.attention }}</span
        >, {{ values[reverseMode]?.street1 }}, {{ values[reverseMode]?.zip_code }}, {{ values[reverseMode]?.city }},
        {{ getCountryName(values[reverseMode]?.country) }}
      </p>
      <Button v-if="!isDFM" type="button" :variant="ButtonVariant.PrimaryLink" @click="addressModalOpen = true">{{
        t("change")
      }}</Button>
    </div>
    <div class="flex flex-col gap-4">
      <AutoComplete
        v-model="selectedContact"
        class="w-full"
        name="saved_receiver_input"
        :loading="loadingContacts"
        :empty-text="t('no_contacts_found')"
        :label="t('search_saved_contacts')"
        :placeholder="t('search_saved_contacts_placeholder')"
        :options="contacts"
        @delete="(val) => deleteContact(val)"
      />
    </div>
    <div class="py-7">
      <Divider :text="t('enter_address_manually')" />
    </div>
    <SettingsToggle
      v-if="showBusinessToggle"
      id="business"
      :name="`${mode}.business`"
      :text="t(`${mode}_is_business`)"
    />
    <div class="mt-4 grid grid-cols-6 gap-4">
      <DropdownSelectCountriesWithPrices
        :id="'country'"
        class="col-span-3"
        :label="t('country')"
        :name="`${mode}.country`"
      />
      <div v-show="isBusiness" class="col-span-3" />
      <template v-if="isBusiness">
        <InputAddressGoogle
          id="name"
          class="col-span-3"
          :name="`${mode}.name`"
          :label="t('company_name')"
          :placeholder="t('name')"
          :country="values[mode].country"
          :highlight-house-number="false"
          @select="fillFromLocation"
        />
        <InputText
          id="attention"
          :class="isBusiness ? 'col-span-3' : 'col-span-6'"
          :name="`${mode}.attention`"
          :label="t('attention_name')"
          :placeholder="t('attention_name')"
        />
      </template>
      <InputText
        v-else
        id="name"
        class="col-span-6"
        :name="`${mode}.name`"
        :label="t('name')"
        :placeholder="t('name')"
      />
      <InputAddressGoogle
        id="street1"
        class="col-span-3"
        :name="`${mode}.street1`"
        :label="t('street1')"
        :placeholder="t('address')"
        :country="values[mode].country"
        @select="fillFromLocation"
      />

      <InputText
        id="street2"
        class="col-span-3"
        :name="`${mode}.street2`"
        :label="t('street2_optional')"
        :placeholder="t('address')"
      />
      <div class="col-span-6 flex justify-between gap-3">
        <InputText
          id="zip_code"
          class="w-full"
          :name="`${mode}.zip_code`"
          :label="t('zip_code')"
          :placeholder="t('zip_code')"
          @update:model-value="autocity"
        />
        <InputText id="city" class="w-full" :name="`${mode}.city`" :label="t('city')" :placeholder="t('city')" />
      </div>
      <InputText id="email" class="col-span-3" :name="`${mode}.email`" :label="t('email')" :placeholder="t('email')" />
      <InputPhone
        id="phone"
        class="col-span-3"
        :set-prefix="setPrefix"
        :name="`${mode}.phone`"
        :label="t('phone')"
        :placeholder="t('phone')"
      />
    </div>
    <div class="mt-5 flex gap-2.5">
      <TickerCheckbox id="save_receiver" :name="`save_${mode}`" />
      <p class="text-body-default text-secondary">{{ t("save_receiver") }}</p>
    </div>
    <AddressModal
      v-if="addressModalOpen"
      :open="addressModalOpen"
      :mode="mode"
      :values="values"
      @close="addressModalOpen = false"
      @update-address="(val) => updateAddress(val)"
    />
  </div>
</template>

<script setup lang="ts">
  import type { GoogleAddressData } from "~/components/Input/AddressGoogle.vue";

  import AddressModal from "./addressModal.vue";

  const { t } = useI18n();
  const selectedContact = ref(null);
  const step = useTemplateRef("step");
  const { isDFM } = useWorkspacesStore();

  const emit = defineEmits(["emitContact", "updateCity", "updateAddress"]);

  const debounceTimeout = ref<NodeJS.Timeout>();

  const addressModalOpen = ref(false);

  const setPrefix = ref(null);

  const props = withDefaults(
    defineProps<{
      values: any;
      mode: "receiver" | "sender";
    }>(),
    {
      values: [],
    }
  );

  const showBusinessToggle = computed(() => {
    if (props.mode === "sender") {
      return !isDFM;
    } else {
      return true;
    }
  });

  const isBusiness = computed(() => {
    if (props.mode === "receiver") {
      return props.values.receiver?.business;
    } else {
      return props.values.sender?.business;
    }
  });

  const reverseMode = computed(() => {
    if (props.mode === "receiver") {
      return "sender";
    } else {
      return "receiver";
    }
  });

  const debounce = () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(() => {
      autocity();
    }, 300); // delay for half second
  };

  watch(
    () => props?.values[props.mode]?.zip_code,
    (value) => {
      if (value) {
        debounce();
      }
    }
  );

  watch(
    () => props?.values[props.mode]?.country,
    (value) => {
      if (value) {
        setPrefix.value = value;
      }
    }
  );

  const autocity = () => {
    if (!props.values[props.mode]) return;
    if (!props.values[props.mode]?.zip_code || !props.values[props.mode]?.country) return;

    homeFetch(
      `/autocity/?zip_code=${props.values[props.mode]?.zip_code}&country=${props.values[props.mode]?.country}`
    ).then((response) => {
      if (response.data.autocity.length > 0) {
        emit("updateCity", response.data.autocity[0].city);
      }
    });
  };

  const updateAddress = (val: object) => {
    addressModalOpen.value = false;
    emit("updateAddress", { data: { ...val, company_name: val.name } });
  };

  watch(
    () => selectedContact.value,
    (value) => {
      if (value) {
        const contact = contacts.value.find((contact) => contact.value === value);
        if (contact) {
          contact.data.phone = contact.data.phone_with_prefix;
          emit("emitContact", contact);
        }
      }
    }
  );

  const fillFromLocation = (data?: GoogleAddressData) => {
    const address = {
      attention: props.values[props.mode]?.attention,
      business: !!data?.businessStatus,
      city: data?.geoLocation?.city || props.values[props.mode]?.city,
      company_name: data?.businessStatus ? data.displayName?.text : null,
      country_code: data?.geoLocation?.country_code?.toUpperCase() || props.values[props.mode]?.country,
      email: props.values[props.mode]?.email,
      name: props.values[props.mode]?.name,
      phone: props.values[props.mode]?.phone,
      phone_prefix: props.values[props.mode]?.phone_prefix,
      street1: data?.geoLocation?.address_line1 || "",
      zip_code: data?.geoLocation?.postcode || props.values[props.mode]?.zip_code,
    };
    emit("emitContact", {
      data: address,
    });
  };

  const { data: contacts, pending: loadingContacts, deleteContact } = await useContacts();
</script>

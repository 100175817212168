<template>
  <div class="bg-surface-lvl-01 min-w-[219px] p-1">
    <div class="flex gap-2.5 self-stretch px-2 py-1.5">
      <slot name="top" />
    </div>
    <div class="my-1.5">
      <InputText v-model="search" :placeholder="t('settings.search')" dark-bg>
        <template #prefix>
          <Icon class="text-quarterary" src="bold/magnifying_glass" />
        </template>
      </InputText>
    </div>
    <div class="flex flex-col gap-1">
      <SettingsSidebarEntry
        v-for="flow in filteredFlows"
        :key="flow.key"
        :entry="flow"
        :active="isCurrentFlow(flow.key)"
        :search="search"
        @click="selectFlow(flow)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import _ from "lodash";
  import type { ISettingsDefinition, ISettingsSidebarEntry } from "~/types/settings";

  const props = withDefaults(
    defineProps<{
      flows: ISettingsDefinition[];
      currentFlow: ISettingsDefinition | null;
    }>(),
    {
      flows: () => [],
      currentFlow: null,
    }
  );
  const { t } = useI18n();

  const search = ref<string>("");
  const isCurrentFlow = (key: string) => {
    return props.currentFlow?.key === key;
  };

  const filteredFlows = computed<ISettingsSidebarEntry[]>(() => {
    //filter flows based on search, looking through title and description
    let entries = props.flows.map<ISettingsSidebarEntry[]>((flowDefinition) => {
      const entries = [];
      const flow = flowDefinition.flow;
      const headers = flow.headers;
      const title = flow.title;
      const description = flow.description;

      if (headers && search.value) {
        //check if any of the flow's headers match the search and push header to entries
        entries.push(
          ...headers
            .filter((header) => {
              return header.toLowerCase().includes(search.value.toLowerCase());
            })
            .map((header) => {
              return {
                title: header,
                key: header,
                parent: flow.key,
              };
            })
        );
      }

      if (
        title.toLowerCase().includes(search.value.toLowerCase()) ||
        (description && description.toLowerCase().includes(search.value.toLowerCase())) ||
        entries.length
      ) {
        //prepend the flow itself to the entries
        entries.unshift(flow);
      }
      return entries;
    });

    //flatten the array of arrays
    entries = _.flatten(entries)
      //remove empty arrays and undefined values
      .filter((entry) => entry);

    return entries;
  });

  const emits = defineEmits(["entry:click"]);

  const selectFlow = (flow) => {
    search.value = "";
    if (flow.parent) {
      //if the selected entry is a header, emit the parent flow
      return emits("entry:click", flow.parent, flow.key);
    }
    return emits("entry:click", flow.key);
  };
</script>

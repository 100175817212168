import type { ISettingsService } from "./types";

declare global {
  const $workspace_settings: ISettingsService;
}

const context = "workspace";

/**
 * A unique identifier to access the provided/injected method
 */
export const SettingsSymbol = Symbol();

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const SettingsService = {
  install: (app) => {
    const SettingsService: ISettingsService = {
      toggle: () => {
        EventBus.$emit("SETTINGS_TOGGLE", context);
      },
      open: () => {
        EventBus.$emit("SETTINGS_OPEN", context);
      },
      close: () => {
        EventBus.$emit("SETTINGS_CLOSE");
      },
      start: (b: string) => {
        EventBus.$emit("SETTINGS_START", b, context);
      },
    };
    app.config.globalProperties.$workspace_settings = SettingsService;
    if (typeof window !== "undefined") window.$workspace_settings = SettingsService;
    app.provide(SettingsSymbol, SettingsService);
  },
};

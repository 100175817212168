<template>
  <div class="bg-00 flex h-[53px] cursor-text items-center gap-3 rounded-[inherit] px-4 py-3.5" @click="focusInput">
    <Icon class="text-foreground-quarterary" src="MagnifyingGlass" :size="ComponentSize.lg" />
    <div
      class="global-search_input-wrapper relative w-full bg-inherit"
      :style="{
        '--gradient-display': gsStore.search ? 'none' : 'block',
        '--gradient-left': gradientLeft + 'px',
        '--gradient-transition-duration': gradientTransitionDuration + 'ms',
      }"
    >
      <input
        v-bind="$attrs"
        ref="input"
        v-model="gsStore.search"
        class="text-title-body w-full bg-inherit leading-none focus-within:outline-none"
        type="text"
        :placeholder="placeholder"
      />
    </div>
    <Button
      v-show="gsStore.search && gsStore.search.length"
      class="!rounded-full"
      :variant="ButtonVariant.Secondary"
      icon-only
      @click="() => (gsStore.search = '')"
    >
      <Icon src="bold/Close" />
    </Button>
  </div>
</template>

<script setup lang="ts">
  defineOptions({
    name: "GlobalSearchInput",
    inheritAttrs: false,
  });

  const props = withDefaults(
    defineProps<{
      show?: boolean;
    }>(),
    {
      show: false,
    }
  );
  const gsStore = useGlobalSearchStore();

  const calculateTextWidth = (text = "") => {
    if (!input.value) return 0;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return 0;

    if (!text) {
      text = placeholder.value;
    }

    context.font = getComputedStyle(input.value).font;
    const width = context.measureText(text).width;

    return width;
  };

  const cShow = computed(() => props.show);
  const input = ref<HTMLInputElement | null>(null);
  const { t } = useI18n();

  const gradientTransitionDuration = ref(0);
  const placeholder = ref(t("search by"));
  const placeholderWords = [
    t("package_number"),
    t("reference"),
    // t("receiver"),
  ];

  const gradientLeft = ref(0);
  let intialGradientLeft = 0;
  const waitToGoBack = 250;

  const focusInput = () => {
    input.value?.focus();
  };

  const setPlaceholder = (index: number) => {
    // Pick the next placeholder word
    const nextWord = placeholderWords[index].toLowerCase();
    const newPlaceholder = t("search by") + " " + nextWord;
    const duration = nextWord.length * 50;

    gradientTransitionDuration.value = duration;
    gradientLeft.value = calculateTextWidth(newPlaceholder);
    placeholder.value = newPlaceholder;

    setTimeout(() => {
      gradientLeft.value = intialGradientLeft;
    }, gradientTransitionDuration.value + waitToGoBack);

    setTimeout(
      () => {
        let nextIndex = index + 1;
        if (nextIndex >= placeholderWords.length) {
          nextIndex = 0;
        }
        setPlaceholder(nextIndex);
      },
      gradientTransitionDuration.value * 2 + waitToGoBack
    );
  };

  watch(
    cShow,
    (value) => {
      if (value) {
        setTimeout(() => {
          gradientLeft.value = calculateTextWidth(placeholder.value);
          const spaceWidth = calculateTextWidth(" ");
          intialGradientLeft = gradientLeft.value - spaceWidth;
          setPlaceholder(0);
        });
      }
    },
    { immediate: true }
  );

  defineExpose({
    focus() {
      input.value?.focus();
    },
  });
</script>

<template>
  <NuxtLayout name="empty">
    <section class="bg-00 relative flex h-screen items-center justify-center p-16">
      <div class="container flex flex-col items-center">
        <div class="flex max-w-lg flex-col gap-4 text-center">
          <div class="text-title-screen">
            {{ errorTitle }}
          </div>
          <p class="text-title-body text-tertiary">{{ errorDescription }}</p>

          <img class="my-10 max-h-[250px]" :src="errorIllustration" alt="Error illustration" />

          <Button class="w-fit" :variant="ButtonVariant.Secondary" @click="handleError">
            <Icon class="mr-2" :size="ComponentSize.lg" src="arrow_left" />
            {{ t("go_back") }}
          </Button>
        </div>
      </div>
      <Badge class="absolute bottom-[30px] left-[30px]" type="subtle">
        {{ statusCode }}
      </Badge>
    </section>
  </NuxtLayout>
</template>

<script setup lang="ts">
  import errorIllustrationImproveSearch from "~/assets/illustrations/errors/nc-improve-search-experience.svg";
  import errorIllustrationOctopus from "~/assets/illustrations/errors/nc-octopus.svg";
  import errorIllustrationTurningOffLamp from "~/assets/illustrations/errors/nc-turning-off-lamp.svg";
  import errorIllustrationWorkEfficiently from "~/assets/illustrations/errors/nc-work-efficiently.svg";
  import errorIllustrationLogin from "~/assets/illustrations/errors/oc-login.svg";

  const lastRoute = ref(null);
  onBeforeMount(() => {
    lastRoute.value = useNuxtApp().$lastRoute.value;
  });

  const { t } = useI18n();
  const error = useError();
  const statusCode = computed(() => error.value?.statusCode);
  const { isUserLoggedIn } = useAuth();

  const handleError = () => {
    if (!lastRoute.value?.name) {
      if (isUserLoggedIn.value) {
        return clearError({ redirect: "/" });
      }
      return clearError({ redirect: "auth/login" });
    } else clearError().then(() => useRouter().back());
  };

  const errorTitle = computed(() => {
    switch (statusCode.value) {
      case 400:
        return t("errors.400.title");
      case 401:
        return t("errors.401.title");
      case 403:
        return t("errors.403.title");
      case 404:
        return t("errors.404.title");
      case 405:
        return t("errors.405.title");
      case 500:
        return t("errors.500.title");
      case 502:
        return t("errors.502.title");
      default:
        return error.value?.message;
    }
  });

  const errorDescription = computed(() => {
    switch (statusCode.value) {
      case 400:
        return t("errors.400.description");
      case 401:
        return t("errors.401.description");
      case 403:
        return t("errors.403.description");
      case 404:
        return t("errors.404.description");
      case 405:
        return t("errors.405.description");
      case 500:
        return t("errors.500.description");
      case 502:
        return t("errors.502.description");
      default:
        return "";
    }
  });

  const errorIllustration = computed(() => {
    switch (statusCode.value) {
      case 400:
        return errorIllustrationOctopus;
      case 401:
        return errorIllustrationLogin;
      case 403:
        return errorIllustrationLogin;
      case 404:
        return errorIllustrationImproveSearch;
      case 405:
        return errorIllustrationWorkEfficiently;
      case 500:
        return errorIllustrationTurningOffLamp;
      case 502:
        return errorIllustrationOctopus;
      default:
        return errorIllustrationOctopus;
    }
  });
</script>

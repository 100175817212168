<template>
  <Modal
    id="settings-modal"
    ref="modal"
    :open="open"
    class="shadow-lvl-05"
    max-width="1020px"
    :content-padding="false"
    @closed="closeModal"
  >
    <div class="flex h-[756px] max-h-full w-[1020px] max-w-full">
      <SettingsSidebar
        :flows="filteredFlows"
        :current-flow="currentFlow"
        @entry:click="(val) => setCurrentFlowKey(val)"
      >
        <template #top>
          <slot name="sidebar-top" />
        </template>
      </SettingsSidebar>
      <div class="relative flex w-full">
        <div ref="flowContainer" class="box-content flex w-full flex-col">
          <div class="overflow-auto py-6">
            <div class="mb-6 flex flex-col gap-[7.5px] px-10">
              <div class="text-title-body">
                {{ currentPageTitle }}
              </div>
              <div v-if="currentPageDescription" class="text-body-lg text-quarterary">
                {{ currentPageDescription }}
              </div>
            </div>
            <div class="px-10 pb-16">
              <ClientOnly>
                <KeepAlive>
                  <component
                    :is="currentFlow?.component"
                    :current-header="currentHeader"
                    @update:loading="setLoading"
                  />
                </KeepAlive>
              </ClientOnly>
            </div>
          </div>
        </div>
        <Overlay v-if="loading" />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import type { ISettingsContext, ISettingsDefinition } from "~/types/settings";

  defineOptions({
    name: "SettingsModal",
  });

  const props = withDefaults(
    defineProps<{
      flows: ISettingsDefinition[];
      modalContext?: ISettingsContext;
    }>(),
    {
      flows: () => [],
      modalContext: "workspace",
    }
  );

  const modal = ref<HTMLDivElement | null | undefined>();
  const loading = ref<boolean>(false);
  const flowContainer = ref<HTMLElement | null | undefined>();
  const currentFlowKey: Ref<null | string> = ref(null);

  const open = ref<boolean>(false);
  const filteredFlows = computed(() => props.flows.filter((flow) => flow.flow.condition));
  const currentHeader = computed(() => currentFlow.value?.flow.title);

  const { t } = useI18n();

  const currentFlow = computed(() => {
    if (!currentFlowKey.value) return null;
    return filteredFlows.value.find((b) => b.key === currentFlowKey.value);
  });

  const setLoading = (l: boolean) => (loading.value = l);

  watch(
    () => flowContainer.value,
    () => {
      if (flowContainer.value) {
        useHasScrollbar(flowContainer.value);
      }
    }
  );

  const currentPageTitle = computed(() => {
    if (!currentFlow.value) return t("settings.no_such_setting");

    if (currentFlow.value?.pageTitle) return currentFlow.value.pageTitle;
    else return currentFlow.value.title;
  });

  const currentPageDescription = computed(() => {
    if (!currentFlow.value) return "";

    return currentFlow.value.description;
  });

  const setCurrentFlowKey = (key: string) => {
    currentFlowKey.value = key;

    //replace #preferences with #preferences-key
    if (import.meta.client) {
      window.history.pushState({}, "", `#${props.modalContext}-${key}`);
    }
  };

  const openModal = () => {
    open.value = true;
    nextTick(() => {
      setCurrentFlowKey(props.flows[0].key);
    });
    //add #workspace to the url
    if (import.meta.client && window.location.hash !== "#" + props.modalContext) {
      window.history.pushState({}, "", "#" + props.modalContext);
    }
  };

  const closeModal = () => {
    open.value = false;
    if (import.meta.client && window.location.hash) {
      //remove hash
      window.history.pushState({}, "", window.location.pathname);
    }
  };

  const startSettings = (key: string) => {
    openModal();
    nextTick(() => {
      setCurrentFlowKey(key);
    });
  };
  onMounted(() => {
    const settingsEvents = [
      [
        "SETTINGS_TOGGLE",
        (ctx: ISettingsContext) => {
          if (ctx === props.modalContext) {
            if (open.value) closeModal();
            else openModal();
          }
        },
      ],
      [
        "SETTINGS_OPEN",
        (ctx: ISettingsContext) => {
          if (ctx === props.modalContext) {
            openModal();
          }
        },
      ],
      ["SETTINGS_CLOSE", closeModal],
      [
        "SETTINGS_START",
        (key: string, ctx: ISettingsContext) => {
          if (ctx === props.modalContext) {
            startSettings(key);
          }
        },
      ],
    ];

    settingsEvents.forEach(([event, handler]) => {
      EventBus.$on(event, handler);
    });

    onUnmounted(() => {
      settingsEvents.forEach(([event]) => {
        EventBus.$off(event);
      });
    });
  });
</script>

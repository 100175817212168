import { inject, ref } from "vue";
import type { IBoardingFlow, IBoardingService } from "./types";

export const open = ref(false);
export const flows = ref<IBoardingFlow[]>([]);
export const currentFlowKey: Ref<null | string> = ref(null);
export const currentFlow = computed(() => {
  if (!currentFlowKey.value) return null;
  return flows.value.find((b) => b.key === currentFlowKey.value);
});

/**
 * A unique identifier to access the provided/injected method
 */
export const BoardingSymbol = Symbol();

/**
 * Composable which allows accessing the Boarding service in Composition API
 * @returns Boarding
 */
export function useBoarding() {
  const Boarding = inject(BoardingSymbol);
  if (!Boarding) {
    throw new Error("No Boarding provided!");
  }

  return Boarding;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const BoardingService = {
  install: (app) => {
    const BoardingService: IBoardingService = {
      toggle: () => {
        EventBus.$emit("BOARDING_TOGGLE");
      },
      open: () => {
        EventBus.$emit("BOARDING_OPEN");
      },
      close: () => {
        EventBus.$emit("BOARDING_CLOSE");
      },
      add: (b: IBoardingFlow) => {
        EventBus.$emit("BOARDING_ADD", b);
      },
      remove: (b: string) => {
        EventBus.$emit("BOARDING_REMOVE", b);
      },
      start: (b: string) => {
        EventBus.$emit("BOARDING_START", b);
      },
    };
    app.config.globalProperties.$Boarding = BoardingService;
    if (typeof window !== "undefined") window.$boarding = BoardingService;
    app.provide(BoardingSymbol, BoardingService);
  },
};

<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SettingsSection :title="t('user_settings.workspace_roles.title')">
      <div class="grid auto-cols-fr grid-cols-2 gap-3">
        <div ref="container" :key="containerKey" class="col-span-2 flex flex-col gap-0.5">
          <div
            v-for="(workspace, index) in workspaces"
            :key="workspace.subdomain || workspace.account.account_id"
            :data-swapy-slot="index"
            class="swapy-slot bg-00 rounded-md transition-all duration-200 ease-out"
          >
            <LayoutNavigationWorkspaceEntry
              :data-swapy-item="workspace.subdomain || workspace.account.account_id"
              :workspace="workspace"
              :index="index"
              :draggable="workspaces.length > 1"
              :hover-effect="false"
              :clickable="false"
            >
              <template #actions>
                <div class="text-quarterary text-sm">{{ workspace.userRoles[0] }}</div>
                <div class="mx-5">
                  <Button
                    :size="ComponentSize.sm"
                    class="equal-width-btn w-43"
                    :disabled="index === 0"
                    @click.stop="onSetDefaultWorkspace(workspace)"
                  >
                    {{ index === 0 ? t("default_workspace") : t("set_as_default") }}
                  </Button>
                </div>
              </template>
            </LayoutNavigationWorkspaceEntry>
          </div>
        </div>
      </div>
    </SettingsSection>
  </div>
</template>

<script setup lang="ts">
  import { useOptionsStore } from "@/stores/Options";
  import { useWorkspacesStore } from "@/stores/Workspaces";
  import _ from "lodash";
  import { storeToRefs } from "pinia";
  import { createSwapy } from "swapy";
  import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import type { ISettingsFlow } from "@/types/settings";
  import type { IWorkspace } from "~/types/auth";

  const container = useTemplateRef("container");
  const containerKey = ref(0);
  const props = withDefaults(defineProps<{ loading?: boolean }>(), { loading: false });
  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
  const optionsStore = useOptionsStore();
  const workspacesStore = useWorkspacesStore();
  const { workspaces } = storeToRefs(workspacesStore);
  const { t } = useI18n();
  const swapy = ref<any>(null);

  function onSetDefaultWorkspace(workspace: IWorkspace) {
    setLoading(true);
    const index = workspaces.value.findIndex((w) => w.account.account_id === workspace.account.account_id);
    if (index > -1) {
      const [moved] = workspaces.value.splice(index, 1);
      workspaces.value.unshift(moved);
      const newOrder = workspaces.value.map((w) => w.account.account_id);
      optionsStore.updateOption("workspace_order", JSON.stringify(newOrder)).finally(() => setLoading(false));
    }
  }

  function reorderWorkspacesFromDOM() {
    if (!container.value) return;
    const children = Array.from(container.value.children);
    children.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
    const newOrderIds = children
      .map(
        (child) =>
          child.getAttribute("data-swapy-item") ||
          child.querySelector("[data-swapy-item]")?.getAttribute("data-swapy-item")
      )
      .filter(Boolean);
    const reordered = newOrderIds
      .map((id) => workspaces.value.find((ws) => (ws.subdomain || ws.account.account_id).toString() === id))
      .filter(Boolean);

    setTimeout(() => {
      workspaces.value = [...reordered];
      containerKey.value++;
    }, 300);
  }

  function onSwapEndHandler(event: any) {
    reorderWorkspacesFromDOM();
    nextTick(() => {
      if (swapy.value && typeof swapy.value.refresh === "function") {
        swapy.value.refresh();
      }
    });
  }

  function initializeSwapy() {
    if (container.value) {
      if (swapy.value) {
        swapy.value.destroy();
      }
      swapy.value = createSwapy(container.value, { dragAxis: "y" });
      if (swapy.value.onSwapEnd) {
        swapy.value.onSwapEnd(onSwapEndHandler);
      } else {
        swapy.value.onSwap(onSwapEndHandler);
      }
    }
  }

  onMounted(() => {
    initializeSwapy();
  });

  watch(containerKey, () => {
    nextTick(() => {
      initializeSwapy();
    });
  });

  onUnmounted(() => {
    swapy.value?.destroy();
  });
</script>

<script lang="ts">
  export const useWorkspaceAndRolesSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    return {
      icon: "regular/Browsers",
      pageTitle: t("user_settings.workspace_roles.title"),
      title: t("user_settings.workspace_roles.title"),
      key: "workspaces_roles",
      condition: true,
    };
  };
</script>

<style>
  .swapy-slot[data-swapy-highlighted] {
    background-color: var(--background-color-01);
  }

  [data-swapy-handle] {
    cursor: grab;
  }

  [data-swapy-handle]:active {
    cursor: grabbing;
  }
</style>

<template>
  <div class="flex w-full">
    <div
      class="mr-3 flex h-11 w-11 items-center justify-center rounded-[10px]"
      :class="{
        'bg-[#7AB55C]': shop.platform == 'shopify',
        'bg-[#92588B]': shop.platform == 'woocommerce',
        'bg-[#E56624]': shop.platform == 'magento-1' || shop.platform == 'magento-2',
        'bg-[#3E9FFF]': shop.platform == 'shopware',
        'bg-foreground-brand': shop.platform == 'custom',
      }"
    >
      <Icon
        v-if="shop.platform == 'shopify'"
        class="text-white"
        :size="ComponentSize.lg"
        src="custom/shopIcons/Shopify"
      />
      <Icon
        v-else-if="shop.platform == 'shopware'"
        class="text-white"
        :size="ComponentSize.lg"
        src="custom/shopIcons/Shopware"
      />
      <Icon
        v-else-if="shop.platform == 'magento-1' || shop.platform == 'magento-2'"
        class="text-white"
        :size="ComponentSize.lg"
        src="custom/shopIcons/Magento"
      />
      <Icon
        v-else-if="shop.platform == 'woocommerce'"
        class="text-white"
        :size="ComponentSize.lg"
        src="custom/shopIcons/Woo"
      />
      <Icon
        v-else-if="shop.platform == 'custom'"
        class="text-white"
        :size="ComponentSize.lg"
        src="custom/shopIcons/Custom"
      />
    </div>
    <div class="mr-4">
      <p class="text-body-lg-heavy text-secondary">{{ shop.name }}</p>
      <p class="text-body-default text-quarterary capitalize">{{ shop.platform }}</p>
    </div>
    <DropdownSelect
      v-model="selectedCheckout"
      class="mr-2 ml-auto"
      :trigger-text="t('add_checkout')"
      :options="checkouts"
      @select="(val) => selectCheckout(val)"
    />
    <Button
      ref="trigger"
      class="mr-2"
      :variant="ButtonVariant.Tertiary"
      :active="showDropdown"
      :label="t('more')"
      @click="toggleDropdown"
    >
      <Icon class="aspect-square" src="bold/dots_three_vertical" />
    </Button>
    <Dropdown
      ref="dropdown"
      v-model:open="showDropdown"
      :toggle-element="trigger"
      :calculate-position="calculatePosition"
    >
      <div class="dropdown__content flex flex-col space-y-1 p-1.5">
        <DropdownItem @click="() => openEditShop()">
          <div class="flex w-full items-center gap-2">
            <div class="overflow-hidden text-nowrap text-ellipsis">{{ t("edit") }}</div>
          </div>
        </DropdownItem>
        <DropdownItem variant="destructive" @click="() => deleteShop()">
          <div class="flex w-full items-center gap-2">
            <div class="overflow-hidden text-nowrap text-ellipsis">{{ t("delete") }}</div>
          </div>
        </DropdownItem>
      </div>
    </Dropdown>
    <Button class="mr-2" :variant="ButtonVariant.Tertiary" :active="isOpen" @click="toggleShop()"
      ><Icon class="transform transition duration-300" :class="{ '-rotate-180': isOpen }" src="caret_down"
    /></Button>
  </div>
  <div class="grid overflow-hidden transition-all duration-300" :class="isOpen ? 'grid-rows-1fr' : 'grid-rows-0'">
    <div class="bg-surface-lvl-01 mt-2 flex w-full gap-1.5 overflow-hidden rounded-xl">
      <div class="min-w-[120px] py-3 pl-3">
        <p class="text-body-sm text-quarterary mb-1.5">{{ t("website") }}</p>
        <p class="text-body-sm text-quarterary mb-1.5">{{ t("checkout") }}</p>
        <p class="text-body-sm text-quarterary mb-1.5">{{ t("token") }}</p>
        <p class="text-body-sm text-quarterary mb-1.5">{{ t("activated") }}</p>
        <p v-if="shop.activation_expires_at && !isExpired()" class="text-body-sm text-quarterary">
          {{ t("activation_expires_at") }}
        </p>
        <p v-if="shop.activation_expires_at && isExpired()" class="text-body-sm text-quarterary">
          {{ t("activation_expired") }}
        </p>
      </div>
      <div class="py-3 pr-3">
        <p class="text-body-sm text-quarterary mb-1.5">{{ shop.shop_url ?? "-" }}</p>
        <p class="text-body-sm text-quarterary mb-1.5">{{ shop?.checkout?.name ?? "-" }}</p>
        <p class="text-body-sm text-quarterary mb-1.5">{{ shop.shop_token ?? "-" }}</p>
        <p class="text-body-sm text-quarterary mb-1.5">{{ shop.activated ? t("yes") : t("no") }}</p>
        <p v-if="shop.activation_expires_at && !isExpired()" class="text-body-sm text-quarterary">
          {{ formatDate(DateTime.fromSQL(shop.activation_expires_at)) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DateTime } from "luxon";

  export interface Webshop {
    activated: boolean;
    activation_code: string;
    activation_expires_at: Date;
    checkout: Checkout;
    checkout_id: number;
    created_at: Date;
    customer_id: number;
    id: number;
    image_url: string;
    name: string;
    pingback_url: string;
    platform: string;
    shop_token: string;
    shop_url: string;
    updated_at: Date;
    version: string;
  }
  export interface Checkout {
    created_at: Date;
    customer_id: number;
    description: string;
    id: number;
    label: string;
    name: string;
    updated_at: Date;
  }

  const { t } = useI18n();
  const { formatDate } = useDate();

  const emit = defineEmits(["update", "openedit"]);

  const isOpen = ref<boolean>(false);

  const props = defineProps<{
    shop: Webshop;
    checkouts: { label: string; value: string; icon: string }[];
  }>();

  const selectedCheckout = computed(() => {
    return props.shop.checkout_id;
  });

  const openEditShop = () => {
    emit("openedit", props.shop);
  };

  const isExpired = () => {
    if (!props.shop.activation_expires_at) {
      return false;
    } else {
      return DateTime.fromSQL(props.shop.activation_expires_at) < DateTime.now();
    }
  };

  const selectCheckout = (val: number) => {
    const shop = { ...props.shop };
    shop.checkout_id = val;
    homeFetch(`workspace/webshops/${props.shop.id}`, {
      method: "PUT",
      body: {
        ...shop,
      },
    }).then(() => {
      emit("update");
    });
  };

  const deleteShop = () => {
    $confirm({
      message: t("webshop_delete_confirm", { shop: props.shop.name }),
      onConfirm: () => {
        homeFetch(`workspace/webshops/${props.shop.id}`, { method: "DELETE" }).then(() => {
          emit("update");
        });
      },
      destructive: true,
    });
  };

  const toggleShop = () => {
    isOpen.value = !isOpen.value;
  };

  const { dropdown, calculatePosition, trigger, showDropdown, toggleDropdown } = useDropdown();
</script>

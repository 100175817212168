<template>
  <div class="mx-auto h-[67vh] max-w-[864px] overflow-y-auto py-7 text-center">
    <Slider
      ref="slider"
      :deselect-item="deselectItem"
      :items="sizes"
      @update:model-value="(val) => selectDimension(val)"
    >
      <template #content="{ item }">
        <div class="relative">
          <div v-if="item.type == 'custom'" class="absolute -top-1 right-0">
            <DropdownActions
              trigger-icon-prefix="bold/DotsThreeVertical"
              :actions="[
                {
                  label: t('delete'),
                  destructive: true,
                  handler: () => deleteDimension(item),
                },
              ]"
            />
          </div>
          <Icon
            class="text-foreground-tertiary mx-auto mb-2 max-w-[32px]"
            src="light/cube_transparent"
            :size="ComponentSize.xl4"
          />
          <p class="text-body-default-heavy text-secondary">
            {{ item.name }}
          </p>
          <p class="text-body-default text-tertiary">{{ item.length }} x {{ item.width }} x {{ item.height }}</p>
        </div>
      </template>
    </Slider>
    <div class="mx-auto max-w-[780px]">
      <div class="mt-5 mb-3 flex w-full items-center gap-4">
        <InputValueXL
          v-model="length"
          type="number"
          suffix="cm"
          required
          min="1"
          :label="t('length')"
          @update:model-value="deselectItem = !deselectItem"
        />
        <Icon class="text-foreground-quarterary mt-6" src="close" :size="ComponentSize.lg" />
        <InputValueXL
          v-model="width"
          type="number"
          suffix="cm"
          required
          min="1"
          :label="t('width')"
          @update:model-value="deselectItem = !deselectItem"
        />
        <Icon class="text-foreground-quarterary mt-6" src="close" :size="ComponentSize.lg" />
        <InputValueXL
          v-model="height"
          type="number"
          suffix="cm"
          required
          min="1"
          :label="t('height')"
          @update:model-value="deselectItem = !deselectItem"
        />
      </div>
      <Button
        class="mx-auto mb-5 w-full max-w-max px-1"
        type="button"
        :disabled="!length || !height || !width"
        :variant="ButtonVariant.SecondaryLink"
        @click="saveSizeOpen = true"
        >{{ t("save_as_standard") }}</Button
      >
      <Divider class="my-5" />
      <div class="flex flex-col items-center gap-2">
        <InputValueXL v-model="formattedWeight" :suffix="displayWeightUnit" required min="1" :label="t('weight')" />
        <Button class="w-fit" :variant="ButtonVariant.Tertiary" type="button" @click="swapDisplayWeightUnit">
          <template #prefix>
            <Icon src="bold/ArrowsDownUp" />
          </template>
          {{ weightMessage }}
        </Button>
      </div>
      <div class="border-00 mx-auto mt-5 flex justify-center rounded-lg border px-5 py-4">
        <div class="flex max-w-[546px] gap-10">
          <img src="~/assets/illustrations/modals/boxsizes.svg" alt="Boomerang" />
          <div class="my-auto">
            <p class="text-body-default-heavy text-secondary text-left">
              {{ t("how_to_measure") }}
            </p>
            <p class="text-body-default text-tertiary text-left">
              {{ t("how_to_measure_desc") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Overlay v-if="!sizes" class="sticky inset-x-0 min-h-[300px] w-full" />
  <Modal ref="modal" v-model:open="saveSizeOpen" class="shadow-lvl-05" max-width="342px" min-width="342px">
    <div class="flex flex-col gap-4 p-4 pb-3">
      <div class="flex flex-col gap-4">
        <InputText v-model="customSizeName" :label="t('name_new_size')" :placeholder="t('size_name')" />
        <p class="text-title-body text-quarterary mx-auto flex items-center gap-2">
          L:{{ length }}cm
          <Icon src="close" :size="ComponentSize.sm" />
          B:{{ width }}cm
          <Icon src="close" :size="ComponentSize.sm" />
          H:{{ height }}cm
        </p>
      </div>
    </div>
    <ModalFooter class="border-t-0">
      <div class="flex w-full gap-3">
        <Button class="ml-auto" @click="saveSizeOpen = false">{{ t("cancel") }}</Button>
        <Button :variant="ButtonVariant.Primary" :disabled="!customSizeName" @click="saveSize"
          >{{ t("save") }} {{ t("size").toLowerCase() }}</Button
        >
      </div>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
  interface Size {
    name: string;
    height: number;
    length: number;
    width: number;
  }

  const { t } = useI18n();

  const customSizeName = ref("");

  const saveSizeOpen = ref(false);

  const deselectItem = ref(false);

  const slider = ref(null);

  const sizes = useState("newshipment_dimensions", () => []);

  const displayWeightUnit = ref(usePreferredWeightUnit());

  const fetchDimensions = () =>
    homeFetch("dimensions").then((res) => {
      if (res?.data) sizes.value = res.data;
      return res;
    });

  const saveSize = () => {
    const size = {
      height: height.value,
      length: length.value,
      width: width.value,
      name: customSizeName.value,
    };
    homeFetch("dimensions", {
      method: "POST",
      body: size,
    })
      .then((res) => {
        const newId = res?.data?.id;
        fetchDimensions().then(() => {
          const dimensionIndex = sizes.value?.findIndex((s) => s.id === newId);

          slider.value?.updateSelection(res?.data, dimensionIndex, true);
        });
      })
      .then(() => {
        $toast.add({
          type: "success",
          text: t("dimension_saved"),
        });
        saveSizeOpen.value = false;
        customSizeName.value = "";
      });
  };

  const selectDimension = (size: Size) => {
    length.value = size?.length;
    height.value = size?.height;
    width.value = size?.width || 10;
  };

  const deleteDimension = (size: Size) => {
    homeFetch(`dimensions/${size.id}`, {
      method: "DELETE",
    }).then(() => {
      sizes.value = sizes.value.filter((s: Size) => s.id !== size.id);
    });
  };

  const { value: height } = useField(() => "height", undefined, {
    syncVModel: true,
  });

  const { value: width } = useField(() => "width", undefined, {
    syncVModel: true,
  });

  const { value: length } = useField(() => "length", undefined, {
    syncVModel: true,
  });

  const { value: weight } = useField<number>(() => "weight", undefined, {
    syncVModel: true,
  });

  const formattedWeight = computed({
    get: () => {
      if (!weight.value) return "";

      return convertWeight(weight.value, "g", displayWeightUnit.value);
    },
    set: (val) => {
      // This is where the weight value is set and we must always convert it to grams
      weight.value = convertWeight(val, displayWeightUnit.value, "g");
    },
  });

  const weightMessage = computed(() => {
    if (useIsPreferredWeightUnitImperial().value) {
      if (displayWeightUnit.value === "oz") return useFormatWeight(weight.value || 0, { unit: "lb" });
      return useFormatWeight(weight.value || 0, { unit: "oz" });
    }

    //convert to best metric unit based on weight and default unit
    switch (displayWeightUnit.value) {
      case "g":
        return useFormatWeight(weight.value || 0, { unit: "kg" });
      case "kg":
        return useFormatWeight(weight.value || 0, { unit: "g" });
      default:
        return "";
    }
  });

  const swapDisplayWeightUnit = () => {
    if (isWeightUnitImperial(displayWeightUnit.value)) {
      displayWeightUnit.value = displayWeightUnit.value === "oz" ? "lb" : "oz";
    } else displayWeightUnit.value = displayWeightUnit.value === "g" ? "kg" : "g";
  };

  onBeforeMount(() => fetchDimensions());
</script>

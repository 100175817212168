import type { PendingDocument } from "~/types/legal";

const documentsLoading = ref(false);
const pendingDocuments = ref<PendingDocument[]>([]);
const pendingDocument = ref<PendingDocument>();
const hasPendingLegal = ref(false);

export default () => {
  const getLegalDocuments = async (): Promise<unknown> => {
    const { isExternalAccess } = useAuth();
    if (isExternalAccess.value) return Promise.resolve();

    return homeFetch("customers/legal-documents").then(({ data }) => {
      if (!data) return;

      if (data.pending_documents && data.pending_documents.length > 0) {
        hasPendingLegal.value = true;
        pendingDocuments.value = data.pending_documents;
        pendingDocument.value = data.pending_documents[0];
      }

      return data;
    });
  };

  const acceptDocument = (document: PendingDocument) => {
    if (document) {
      documentsLoading.value = true;
      homeFetch(`customers/legal-documents/${document.id}`, {
        method: "POST",
      }).then(() => {
        documentsLoading.value = false;
        pendingDocuments.value = pendingDocuments.value?.filter((item) => item.id !== document.id);
        if (pendingDocuments.value?.length !== 0) {
          pendingDocument.value = pendingDocuments.value ? pendingDocuments.value[0] : undefined;
        } else {
          hasPendingLegal.value = false;
        }
      });
    }
  };

  return {
    getLegalDocuments,
    acceptDocument,
    documentsLoading,
    pendingDocuments,
    pendingDocument,
    hasPendingLegal,
  };
};

import _ from "lodash";
import { DateTime } from "luxon";
import type { WeekdayNumbers } from "luxon";

export default () => {
  const { getOption } = useOptionsStore();

  const getTimezoneOption = (): string => {
    return getOption("timezone", "") as string;
  };

  const convertDate = (date: string | DateTime | Date | null | undefined, timezone: string | null = null) => {
    if (!date) return;
    const _timezone = timezone || getTimeZone();
    const { $i18n } = useNuxtApp();
    let newDate = undefined;
    if (date instanceof DateTime) {
      newDate = date;
    } else if (date instanceof Date) {
      newDate = DateTime.fromJSDate(date);
    } else {
      newDate = DateTime.fromISO(date);
      if (!newDate.isValid) {
        newDate = DateTime.fromSQL(date);
      }
    }

    return newDate.setLocale($i18n.locale.value).setZone(_timezone);
  };

  const formatDate = (
    date?: string | null | undefined | DateTime | Date,
    format: string | Intl.DateTimeFormatOptions | "relative" | "eta" = "relative",
    fallbackFormat: string = "ff",
    relativeTimestamp: boolean = false,
    timezone: string | null = null
  ) => {
    date = convertDate(date, timezone);
    if (!date) return "";

    if (format === "eta") {
      return relativeEta(date, timezone);
    }

    if (format === "relative") {
      //if more than 3 days ago or in the future, show the full date
      if (date.diffNow("days").days > 3 || date.diffNow("days").days < -3) {
        return date.toFormat(fallbackFormat);
      }

      return relativeTime(date, timezone, relativeTimestamp);
    }

    if (isString(format)) return date.toFormat(format);

    return date.toLocaleString(format);
  };

  const isToday = (date?: string | DateTime | Date | null | undefined, timezone: string | null = null) => {
    if (!date) return false;
    const _timezone = timezone || getTimeZone();
    return convertDate(date, _timezone)?.hasSame(getNow(_timezone), "day") || false;
  };

  const getWeekdays = (): { value: string; label: string }[] => {
    const { $i18n } = useNuxtApp();

    //generate a list of all weekdays in the given locale in the format {label: "Monday", value: 1}
    const weekdays = [];
    for (let i = 1; i <= 7; i++) {
      const weekday = i as WeekdayNumbers;
      weekdays.push({
        label: _.capitalize(DateTime.fromObject({ weekday }).setLocale($i18n.locale.value).toFormat("cccc")),
        value: DateTime.fromObject({ weekday }).setLocale("en").toFormat("ccc").toLowerCase(),
      });
    }

    return weekdays;
  };

  const getNow = (timezone: string | null = null) => {
    const { $i18n } = useNuxtApp();
    const _timezone = timezone || getTimeZone();

    return DateTime.now().setZone(_timezone).setLocale($i18n.locale.value);
  };

  const getTimeZone = () => {
    return getTimezoneOption() || Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const timezone = computed(() => getTimeZone());

  const getDateRounded5Minute = (date = new Date()) => {
    const isDateString = typeof date === "string";
    if (isDateString) {
      date = new Date(date);
    }

    if (!(date instanceof Date)) {
      throw new Error("Invalid date provided");
    }

    //round to nearest 5 minutes
    date.setMinutes(Math.ceil(date.getMinutes() / 5) * 5);

    return date;
  };

  const relativeTime = (
    date: string | DateTime | Date | null | undefined,
    timezone: string | null = null,
    relativeTimestamp: boolean = false
  ) => {
    const _timezone = timezone || getTimeZone();
    const _date = convertDate(date, _timezone);
    if (!_date) return "";
    const { $i18n } = useNuxtApp();
    const relativeStyle = $i18n.locale.value === "da" ? "short" : "long";

    if (relativeTimestamp) {
      return `${_date.toRelative({ style: relativeStyle })}, ${_date.toFormat("t")}`;
    }

    return _date.toRelative({ style: relativeStyle }) || "";
  };

  const relativeEta = (date: string | DateTime | Date | null | undefined, timezone: string | null = null) => {
    const _date = convertDate(date, timezone || getTimeZone());
    if (!_date) return "";

    const { t } = useI18n();
    const now = getNow(timezone || getTimeZone());

    // If the date is in the past
    if (_date < now) return t("asap");

    // If today
    if (_date.hasSame(now, "day")) {
      const diff = _date.diffNow(["hours", "minutes"]);
      let diffHours = Math.floor(diff.hours);
      let diffMinutes = Math.ceil(diff.minutes);

      if (diffHours === 0 && diffMinutes === 0) return t("asap");

      // Check if minutes equal 60, then round up to the next hour
      if (diffMinutes === 60) {
        diffHours += 1;
        diffMinutes = 0;
      }

      if (diffHours > 0) {
        const roundedHours = diffMinutes > 0 ? diffHours + 1 : diffHours;
        return `${t("within")} ${roundedHours} ${t("hours", roundedHours).toLowerCase()}`;
      }
      return `${t("within")} ${diffMinutes} ${t("minutes", diffMinutes).toLowerCase()}`;
    }

    // If tomorrow
    if (_date.hasSame(now.plus({ days: 1 }), "day")) return t("tomorrow");

    // If later than tomorrow
    return _date.toFormat("ff");
  };

  return {
    convertDate,
    formatDate,
    getDateRounded5Minute,
    getNow,
    getTimeZone,
    getWeekdays,
    isToday,
    relativeTime,
    relativeEta,
    timezone,
  };
};

<template>
  <SettingsSection :title="t('user_settings.profile.timezone.title')">
    <div class="flex w-full flex-col justify-between gap-3">
      <TickerCheckbox
        :model-value="!timezone"
        :label="t('use_automatic_timezone')"
        @update:model-value="onTimezoneCheckboxChange"
      />
      <DropdownSelect
        :model-value="timezone"
        :options="timezones"
        :placeholder="browserTimezone"
        :label="t('timezone')"
        :trigger-text="getTimezoneByCode(browserTimezone)?.name"
        :readonly="!timezone"
        @select="updateTimezone"
      />
      <div class="text-body-sm text-tertiary">
        {{ t("user_settings.profile.timezone.description") }}
      </div>
    </div>
  </SettingsSection>
</template>

<script setup lang="ts">
  const { t } = useI18n();
  const { getOption, updateOption, deleteOption } = useOptionsStore();

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = ref<TimeZone["tzCode"]>(getOption<TimeZone["tzCode"]>("timezone", "") as TimeZone["tzCode"]);
  const timezones = getTimezonesAsSelectOptions();

  const onTimezoneCheckboxChange = (val: boolean) => {
    if (val) {
      updateTimezone("");
    } else {
      updateTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  };

  const updateTimezone = (tz: TimeZone["tzCode"]) => {
    const previous = timezone.value;
    timezone.value = tz;

    if (!tz) {
      deleteOption("timezone").catch(() => {
        timezone.value = previous;
      });
    }

    updateOption("timezone", tz).catch(() => {
      timezone.value = previous;
    });
  };
</script>

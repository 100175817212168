<template>
  <div
    class="group relative cursor-pointer rounded-xl"
    :class="small ? 'h-[68px] w-[68px]' : 'h-[82px] w-[82px]'"
    :style="`background-image: url('${src}'); background-position: center; background-size: cover;`"
  >
    <Button
      v-if="!small"
      class="absolute top-1 left-[49px] opacity-0 transition-all duration-300 group-hover:opacity-100"
      :variant="ButtonVariant.Primary"
      :size="ComponentSize.sm"
      icon-only
      @click="emit('removeFile', file.name)"
    >
      <Icon class="text-white" src="close" :size="ComponentSize.sm" />
    </Button>
  </div>
</template>

<script setup lang="ts">
  defineProps<{
    src: string;
    file?: object;
    small?: boolean;
  }>();

  const emit = defineEmits(["removeFile"]);
</script>

<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <SettingsSection
      :title="t('user_settings.access_log.page_title')"
      :description="t('user_settings.access_log.description')"
    >
      <div class="flex w-full flex-col justify-between gap-3">
        <div v-for="[entryDate, _logs] in Object.entries(logs)" :key="entryDate" class="grid grid-cols-4 gap-10">
          <div class="col-span-1">
            <div class="text-body-default-heavy text-quarterary">
              {{ formatDate(entryDate, DateTime.DATE_FULL) }}
            </div>
          </div>
          <div class="col-span-3 flex flex-col gap-3">
            <div v-for="log in _logs" :key="log.id" class="flex flex-col gap-1">
              <div class="text-body-default text-secondary flex items-center gap-1.5">
                <div>{{ formatDate(log.created_at, DateTime.TIME_24_SIMPLE) }} {{ t("via").toLowerCase() }}</div>
                <template v-if="resolveDeviceTypeFromUserAgent(log.user_agent) === 'Mobile'">
                  <Icon src="regular/DeviceMobileCamera" :size="ComponentSize.xl" />
                  {{ resolvePhoneModelFromUserAgent(log.user_agent) }}
                </template>
                <template v-else>
                  <Icon src="regular/Desktop" :size="ComponentSize.xl" />
                  homerunner.com
                </template>
                <Icon
                  v-if="!['credentials', undefined].includes(log.provider)"
                  :src="`custom/services/${log.provider}`"
                  filled
                />
              </div>
              <div class="text-body-sm text-tertiary">
                IP {{ log.ip }}.
                {{
                  log.count === 1
                    ? ""
                    : t("access_log.ip_description", {
                        ip: log.ip,
                        count: Math.max(0, log.count - 1),
                        time: formatDate(log.created_at, DateTime.TIME_24_SIMPLE),
                      })
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsSection>
  </div>
  <SettingsNotificationWrapper />
</template>

<script setup lang="ts">
  import _ from "lodash";
  import { DateTime } from "luxon";
  import type { IAccessLog, ISettingsFlow } from "@/types/settings";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
      loading: false,
    }
  );
  const { t } = useI18n();
  const { formatDate } = useDate();
  const logs = ref<Record<string, IAccessLog[]>>({});
  onMounted(() => {
    goToCurrentHeader();
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  const getLogs = () => {
    setLoading(true);
    homeFetch<Record<string, IAccessLog[]>>("settings/logs")
      .then((r) => {
        logs.value = r.data || {};
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resolveDeviceTypeFromUserAgent = (userAgent: string) => {
    if (userAgent.includes("Mobile") || userAgent.includes("Tablet")) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  };

  const resolvePhoneModelFromUserAgent = (userAgent: string) => {
    if (userAgent.includes("iPhone")) {
      return "iOS (iPhone)";
    } else if (userAgent.includes("iPad")) {
      return "iOS (iPad)";
    } else if (userAgent.includes("iPod")) {
      return "iOS (iPod)";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else {
      return "";
    }
  };

  onMounted(() => {
    getLogs();
  });
</script>

<script lang="ts">
  export const useAccessLogSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/UserList";
    const title = t("user_settings.access_log.title");
    const pageTitle = t("user_settings.access_log.page_title");
    const key = "access_log";
    const headers = [t("user_settings.security.password.title"), t("user_settings.security.sso.title")];
    const condition = computed(() => true);

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
      condition,
    };
  };
</script>

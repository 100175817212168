<template>
  <SettingsModal :flows="allFlows" modal-context="workspace">
    <template #sidebar-top>
      <AvatarOrganisation />
      <div class="text-primary mr-auto flex flex-col overflow-hidden text-sm font-medium">
        <span>{{ accountName }}</span>
        <span class="text-tertiary text-xs text-nowrap text-ellipsis">{{ currentDomain }}</span>
      </div>
    </template>
  </SettingsModal>
</template>

<script setup lang="ts">
  import type { ISettingsDefinition } from "~/types/settings";

  import CheckoutSettings, { useCheckoutSettings } from "../flows/CheckoutSettings.vue";
  import IntegrationSettings, { useIntegrationSettings } from "../flows/IntegrationSettings.vue";
  import OrganisationSettings, { useOrganisationSettings } from "../flows/OrganisationSettings.vue";
  import PaymentSettings, { usePaymentSettings } from "../flows/PaymentSettings.vue";
  import PlanSettings, { usePlanSettings } from "../flows/PlanSettings.vue";
  import PrinterSettings, { usePrinterSettings } from "../flows/PrinterSettings.vue";
  import ReturnPortalSettings, { useReturnPortalSettings } from "../flows/ReturnPortalSettings.vue";
  import SecuritySettings, { useSecuritySettings } from "../flows/SecuritySettings.vue";
  import UserSettings, { useUserSettings } from "../flows/UserSettings.vue";
  import WebshopSettings, { useWebshopSettings } from "../flows/WebshopSettings.vue";

  defineOptions({
    name: "WorkspaceSettingsModal",
  });

  const allFlows = ref<ISettingsDefinition[]>([
    {
      key: "organisation",
      component: markRaw(OrganisationSettings),
      flow: useOrganisationSettings(),
    },
    {
      key: "plan",
      component: markRaw(PlanSettings),
      flow: usePlanSettings(),
    },
    {
      key: "security",
      component: markRaw(SecuritySettings),
      flow: useSecuritySettings(),
    },
    {
      key: "users",
      component: markRaw(UserSettings),
      flow: useUserSettings(),
    },
    {
      key: "printers",
      component: markRaw(PrinterSettings),
      flow: usePrinterSettings(),
    },
    {
      key: "integration",
      component: markRaw(IntegrationSettings),
      flow: useIntegrationSettings(),
    },
    {
      key: "webshops",
      component: markRaw(WebshopSettings),
      flow: useWebshopSettings(),
    },
    {
      key: "checkouts",
      component: markRaw(CheckoutSettings),
      flow: useCheckoutSettings(),
    },
    {
      key: "returnPortals",
      component: markRaw(ReturnPortalSettings),
      flow: useReturnPortalSettings(),
    },
    {
      key: "payment",
      component: markRaw(PaymentSettings),
      flow: usePaymentSettings(),
    },
  ]);
  const { accountName, currentDomain } = storeToRefs(useWorkspacesStore());
</script>

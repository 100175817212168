import Echo from "laravel-echo";
import QueryString from "qs";
import socketIo from "socket.io-client";

const client = ref<Echo<"socket.io">>();

export default () => {
  const importerStates = ["awaiting", "failed", "done"];
  const { accountId } = storeToRefs(useWorkspacesStore());
  const { t } = useNuxtApp().$i18n;

  const getEchoClient = () => {
    if (!client.value) {
      const { getAuthCookie } = useAuth();

      client.value = new Echo({
        broadcaster: "socket.io",
        client: socketIo,
        host: `https://core.coolrunner.dk`,
        encrypted: true,
        secure: true,
        rejectUnauthorized: false,
        transports: ["websocket"],
        auth: {
          headers: {
            AccountId: accountId.value,
            AccessToken: getAuthCookie().value,
            Context: "socket_new",
          },
        },
      });
    }

    return client.value;
  };

  const hasEchoClient = computed(() => !!client.value);

  const destroyEchoClient = () => {
    if (!hasEchoClient.value) return;
    client.value!.leaveAllChannels();
    client.value!.disconnect();
    client.value = undefined;
  };

  const unbindEchoListeners = () => {
    if (!hasEchoClient.value) return;
    const echo = getEchoClient();
    echo.leaveAllChannels();
  };

  const setupBaseListeners = () => {
    importerStates.forEach((key) => {
      bindListener(`order_import.${accountId.value}`, key, () => {
        switch (key) {
          case "done":
            onImportDone();
            break;
          case "awaiting":
            onImportAwaiting();
            break;
          case "failed":
            onImportFailed();
            break;
        }
      });
    });
  };

  const destroyBaseListeners = () => {
    if (!hasEchoClient.value) return;
    const echo = getEchoClient();
    importerStates.forEach((key) => {
      echo.private(`order_import.${accountId.value}`).stopListening(`.${key}`);
    });

    destroyEchoClient();
  };

  const onImportDone = () => {
    const base = useRouter().resolve({ name: "shipments" });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "done" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_import_done",
      title: t("new_shipment_generated"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onImportAwaiting = () => {
    const base = useRouter().resolve({
      name: "shipments-import-list",
    });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "awaiting" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_imported",
      title: t("new_shipment_imported"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onImportFailed = () => {
    const base = useRouter().resolve({
      name: "shipments-import-list",
    });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "failed" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_import_failed",
      title: t("new_shipment_failed"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const bindListener = (channel: string, event: string, callback: (e: any) => void) => {
    const echo = getEchoClient();
    echo.private(channel).listen(event, callback);
  };

  const unbindListener = (channel: string, event: string) => {
    const echo = getEchoClient();
    echo.private(channel).stopListening(event);
  };

  return {
    getEchoClient,
    hasEchoClient,
    destroyEchoClient,
    setupBaseListeners,
    destroyBaseListeners,
    unbindEchoListeners,
    bindListener,
    unbindListener,
  };
};

<template>
  <div class="flex w-full flex-col gap-3">
    <div v-for="(object, index) in objects" :key="index" class="flex w-full gap-3">
      <div v-for="field in service.fields" :key="field.key" class="text-left">
        <InputText
          v-if="field.type == 'text'"
          v-model="objects[index][field.key]"
          class="w-full"
          type="text"
          :label="field.label"
        />
        <InputText
          v-if="field.type == 'number'"
          v-model="objects[index][field.key]"
          class="w-full"
          type="number"
          :label="field.label"
        />
        <DropdownSelect
          v-if="field.type == 'select'"
          v-model="objects[index][field.key]"
          :options="field.options"
          :label="field.label"
        />
      </div>
      <Button
        v-if="index !== 0"
        class="mt-[22.5px]"
        type="button"
        :variant="ButtonVariant.Tertiary"
        icon-only
        @click="removeField(index)"
        ><Icon src="close"
      /></Button>
    </div>
    <Button class="mr-auto" type="button" :variant="ButtonVariant.PrimaryLink" @click="addField()"
      >+ {{ t("add_field") }}</Button
    >
  </div>
</template>

<script setup lang="ts">
  import { defineProps, onMounted } from "vue";

  const { t } = useI18n();
  const props = defineProps<{
    service: any;
  }>();

  onMounted(() => {
    if (!objects.value) {
      objects.value = [];
      const fieldArray = {};
      props.service.fields.forEach((field: any) => {
        fieldArray[field.key] = "";
      });
      objects.value.push(fieldArray);
    }
  });

  const addField = () => {
    const fieldArray = {};
    props.service.fields.forEach((field: any) => {
      fieldArray[field.key] = "";
    });
    objects.value.push(fieldArray);
  };

  const removeField = (index: number) => {
    objects.value.splice(index, 1);
  };

  const { value: objects } = useField(() => `services.${props.service.carrier_key}`, undefined, {
    syncVModel: false,
    controlled: true,
  });
</script>
